<template>
  <div class="container is-max-desktop" >
    <header class="sub-heading p-3">
      <h2 class="is-size-4" ></h2>
    </header>
    <div class="card" >
      <header class="card-header">
        <p class="card-header-title" v-if="!bookingSent" >
          Booking Form:<br />
        </p>
        <p id="success-message" class="card-header-title has-background-primary has-text-white" v-if="bookingSent" >
          Thank You, your booking has been sent!
        </p>
        <!--<button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>-->
      </header>
      <div class="card-content">        
        <div class="content has-text-left">
          <form v-if="!bookingSent" >
            
            <PassengerSelect
              :userId="userId"
              :errorMessage="passengersError"
              @updatePassengerList="updatePassengerList"
              @updateMyDetailsAtClub="updateMyDetailsAtClub"

            />
            <PetSelect
              :userId="userId"
              @updatePetsList="updatePetsList"
            />
            
            <fieldset>
              <legend class="subtitle p-0 pt-6 py-1 m-0 routes" >
                <div class="has-background-warning is-flex is-justify-content-space-between is-align-content-center" > 
                  <p class="p-3 m-0" >Route, Travel Dates and Times:</p>
                  <p class="has-background-info p-3 m-0 has-text-white" ><label for="oneWay" class="is-clickable" >My Journey is one-way/no-return:</label> <input class="is-clickable" id="oneWay" type="checkbox" v-model="oneWay" ></p>
                </div>
              </legend>
              <!-- tabs journey -->
              <!-- <div class="tabs journey">
                <ul class="ml-0" >
                  <li :class="((activeTabJourney === 'journey-outbound') === true ? 'is-active' : '') + ' tab-link'" ><a class="button is-info has-text-white" @click="swapTabs('journey', 'outbound')" >Outbound</a></li>
                  <li v-if="!oneWay" :class="((activeTabJourney === 'journey-return') === true ? 'is-active' : '') + ' tab-link'" ><a class="button is-danger has-text-white ml-1" @click="swapTabs('journey', 'return')" >Return</a></li>
                </ul>                
              </div> 
              <div class="tab-content-wrapper" > 
                <div :class="((activeTabJourney === 'journey-outbound') ? 'is-active' : '') + ' tab-content'" >
                  <IrishFerriesTimeTablePicker
                    :uid="'outbound'"
                    :background="'has-background-info'"       
                    :Title="'Outward Sailing'"
                    
                    @clearLoading="clearLoading"
                    @selectSailing="selectSailing"

                  />
                </div>
                <div v-if="!oneWay" :class="((activeTabJourney === 'journey-return') ? 'is-active' : '') + ' tab-content'" >
                  <IrishFerriesTimeTablePicker                  
                    :uid="'return'"
                    :background="'has-background-danger'"       
                    :Title="'Return Sailing'"
                    @clearLoading="clearLoading"
                    @selectSailing="selectSailing"

                  />
                </div>
              </div>-->
              <!-- .tabs journey -->
              <IrishFerriesTimeTablePicker
                :uid="'outbound'"
                :background="'has-background-info'"       
                :Title="'Outward Sailing'"
                :errorMessage="outBoundError"
                
                @clearLoading="clearLoading"
                @selectSailing="selectSailing"
                

              />
              <IrishFerriesTimeTablePicker
                v-if="!oneWay"                  
                :uid="'return'"
                :background="'has-background-danger'"       
                :Title="'Return Sailing'"
                :errorMessage="returnError"

                @clearLoading="clearLoading"
                @selectSailing="selectSailing"

              />
            </fieldset>
            <fieldset>
              <legend class="subtitle p-0 pt-6 py-1 m-0 vehicles" >
                <p class="p-3 m-0" >Mode of Travel:</p>
              </legend>
              <VehiclesSelect
                :uid="'vehicle'"
                :background="'has-background-warning'"       
                :Title="'How are you travelling?'"

                :outBoundFpSelected="outBoundFpSelected"
                :returnFpSelected="returnFpSelected"
                :errorMessage="vehicleError"
                
                @updateVehicleType="updateVehicleType"
                @updateVehicleReg="updateVehicleReg"
                @updateVehicleSizeLength="updateVehicleSizeLength"

                @updateHasCaravanTrailer="updateHasCaravanTrailer"
                @updateCaravanTrailerSelection="updateCaravanTrailerSelection"

                @updateExtras="updateExtras"
                @updateTermsF="updateTermsF"
                

              />
            </fieldset>
            <fieldset>
              <legend class="subtitle p-0 pt-6 py-1 m-0 vehicles" >
                <p class="p-3 m-0" >Desired Cabin:</p>
              </legend>
              <CabinsSelect 

                :uid="'outbound'"
                :background="'has-background-info has-text-white'"       
                :Title="'Select your desired accommodation'"
                :Cabins="outboundCabins"
                :errorMessage="outboundCabinError"
                :requiresAccommodation="outboundRequiresAccommodation"
                @updateSelectedCabin="updateSelectedCabin"
              
              />

              <CabinsSelect 
                v-if="!oneWay"
                :uid="'return'"
                :background="'has-background-danger has-text-white'"       
                :Title="'Select your desired return accommodation'"
                :Cabins="returnCabins"
                :errorMessage="returnCabinError"
                :requiresAccommodation="returnRequiresAccommodation"
                @updateSelectedCabin="updateSelectedCabin"
              
              />
            </fieldset>
            <p class="my-3"><button class="button is-success is-fullwidth" @click="sendForm" >Send Booking Request</button></p>
            <p class="is-flex is-justify-content-space-around is-align-content-center" ><a class="is-warning" @click="back" >Back</a></p>            
          </form>
        </div>
      </div>
    </div>
  </div>
  <LoadingOverlay 
    :active="isLoading" 
    :can-cancel="false"
    :is-full-page="fullPage" 
  />
</template>
<script>
  import store from '@/store';
  import config from '@/config';
  import axios from 'axios';
  import he from 'he'; 
  
  import PassengerSelect from '@/components/forms/irish-ferries/PassengerSelect';
  import PetSelect from '@/components/forms/irish-ferries/PetSelect';
  import IrishFerriesTimeTablePicker from '@/components/forms/irish-ferries/IrishFerriesTimeTablePicker';
  import VehiclesSelect from '@/components/forms/irish-ferries/VehiclesSelect';
  import CabinsSelect from '@/components/forms/irish-ferries/CabinsSelect';
  // Import component
  import LoadingOverlay from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    props: {
      userId: Number,
      offerId: Number
    },
    emits: ["back"],
    components: {
        PassengerSelect,
        PetSelect,
        IrishFerriesTimeTablePicker,
        VehiclesSelect,
        CabinsSelect,
        LoadingOverlay            
    },
    store: store,
    data() {
      return {
        base_url: '',
        user_id: 0,
        user: {},
        fn: '',
        ln: '',        
        em: '',
        pw: '',
        pwConfirm: '',
        dob: '',
        phone: '',
        nationality: '',
        requirements: '',
        bookingOther: false,
        user_original_details:{},
        isLoading: true,
        fullPage: true,
        loadingTimeOut: 3600,
        bookingSent: false,

        /// tabs
        activeTabJourney: 'journey-outbound',
        
        updateMemberDetailsAtClub: false,
        ////
        personsTravelling: [
          
        ],
        pets: [],
        
        ////
        currentPerson: -1,

        errors: [],
        passengersError: '',
        outBoundError: '',
        returnError: '',
        vehicleError: '',
        outboundCabinError: '',
        returnCabinError: '',

        oneWay: false,
        tryLoginAgain: false,
        isEditingPerson: false,
        currentMembership: {},
        action: 'book',
        offerType: 'IrishFerries',
        
        hasCaravanTrailer: false,
        vehicleType: '',
        vehicleReg: '',
        termsF: false,
        vehicleSizeLength: '',
        caravanTrailerSelection: '',
        confirmTerms: false,
        ExtrasEvCharging: false,
        
        selectedOutBoundJourney: {},
        outBoundFpSelected: 'Y',
        outboundRequiresAccommodation: 'Y',
        selectedReturnJourney: {},
        returnFpSelected: 'Y',
        returnRequiresAccommodation: 'Y',
        

        outboundCabins: [],
        returnCabins: [],
        selectedOutBoundCabin:'',
        selectedReturnCabin:''         

      }
    },
    mounted: function() {

      console.log('IrishFerries form view', this, store.state.user, this.IrishFerriesTimetable);

    },
    async created() {

      this.base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
      ////this.loadMember();

    },
    computed: {
      form_random_key(){
        return this.setUpId + '_' + this.randomCode();
      },
      form_random_keyCheck(){
        return this.captchaCode();
      }
    },
    watch: {
      oneWay(newVal){
        if(newVal) {
          this.selectedReturnJourney= {};
          this.returnFpSelected = 'Y';
          this.returnRequiresAccommodation = 'Y';
          this.returnCabins = [];
          this.selectedReturnCabin = '';
        }
      }
    },
    methods: {
      clearLoading(){
        setTimeout(() => this.isLoading = false, this.loadingTimeOut);
      },
      captchaCode(length = 8) {
          const possible_letters = '23456789bcdfghjkmnpqrstvwxyz';
          /*const random_dots = 0;
          const random_lines = 20;*/
          let code = '';
          for (let i = 0; i < length; i++) {
              let rand = Math.round(Math.random(0, possible_letters.length - 1) * 10);
              code += possible_letters[rand];
          }
          return code;
      },
      randomCode: function(length = 10) {
          const charLength = Math.round(length * 0.8);
          const numLength = Math.round(length * 0.2);
          const keywords = [
              {
                "count": charLength,
                "char": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
              },
              {
                "count": numLength,
                "char": "0123456789"
              }
            ];
          
          const temp_array = keywords.map(function(char_set) {
              let n_array = [];
              for (let i = 0; i < char_set['count']; i++) {
                n_array.push(char_set['char'][Math.round(Math.random(0, char_set['char'].length - 1) * 10)]);
              }
              return n_array.join('');
          }); 
          return temp_array.join('');
      },      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      swapTabs(direction, selection) {
        
        this.activeTabJourney = direction+'-'+selection;
        console.log(direction, selection, this.activeTabJourney);

      },
      
      updatePassengerList(personsList){
        console.log(personsList);
        this.personsTravelling = personsList;
      },
      updatePetsList(pets){
        console.log(pets);
        this.pets = pets;
      },      
      updateVehicleType(newVal) {
        this.vehicleType = newVal;
      },
      updateVehicleReg(newVal){
        this.vehicleReg = newVal;
      },
      updateVehicleSizeLength(newVal) {
        this.vehicleSizeLength = newVal;
      },
      updateHasCaravanTrailer(newVal) {
        this.hasCaravanTrailer = newVal;
      },
      updateCaravanTrailerSelection(newVal){
        this.caravanTrailerSelection = newVal;
      },
      updateExtras(newVal){
        this.ExtrasEvCharging = newVal;
      },
      updateTermsF(newVal){
        this.termsF = newVal;
      },
      selectSailing(type, fp, sailingDate, route, departs, from, ferry, cabins, requiresAccommodation){

        console.log('newVal', type, fp, sailingDate, route, departs, from, ferry, cabins);
        const journey = {
          sailingDate: sailingDate,
          departs: departs,
          route: route,
          from: from,
          ferry: ferry,
          fp: fp,
          requiresAccommodation: requiresAccommodation
        };

        if(type === 'outbound'){
          this.selectedOutBoundJourney = journey;
          this.outBoundFpSelected = fp;
          this.outboundCabins = cabins;    
          this.outboundRequiresAccommodation = requiresAccommodation;      
          console.log('n', sailingDate, journey, sailingDate, from, ferry, cabins);
          if(!this.oneWay) {
            document.getElementById('timetable-return').scrollIntoView({
              behavior: 'smooth'
            });
          } else {
            document.getElementById('vehicle-select').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }

        if(type === 'return'){
          this.selectedReturnJourney = journey;
          this.returnFpSelected = fp;
          this.returnRequiresAccommodation = requiresAccommodation;       
          this.returnCabins = cabins;
          document.getElementById('vehicle-select').scrollIntoView({
            behavior: 'smooth'
          });
        }        

      },
      updateSelectedCabin(type, cabin){
        if(type === 'outbound'){
          this.selectedOutBoundCabin = cabin;
        }

        if(type === 'return'){
          this.selectedReturnCabin = cabin;
        }
      },
      updateMyDetailsAtClub(newVal){
        console.log('updateMemberDetailsAtClub' , newVal);
        this.updateMemberDetailsAtClub = newVal;
      },
      //// Keep here
      sendForm(e){
        e.preventDefault()
        console.log('called sendBooking...', Object.keys(this.selectedOutBoundJourney).length);
        let isError = false;

        switch(true){
          
          case this.personsTravelling[0].fn === '':
            document.getElementById('fn').reportValidity();
            document.getElementById('fn').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;
          case this.personsTravelling[0].ln === '':
            document.getElementById('ln').reportValidity();
            document.getElementById('ln').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;
          case this.personsTravelling[0].em === '' || (this.personsTravelling[0].em.indexOf('portsmouthirishclub.org') !== -1):
            document.getElementById('email').setCustomValidity("The email connot be blank or be a Portsmouth Irish club email");
            document.getElementById('email').reportValidity();
            document.getElementById('email').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;
          case this.personsTravelling[0].phone === '':
            document.getElementById('phone').reportValidity();
            document.getElementById('phone').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;
          case this.personsTravelling[0].dob === '':
            document.getElementById('dob').reportValidity();
            isError = true;
            break;
          case this.personsTravelling[0].nationality === '':
            document.getElementById('nationality').reportValidity();
            document.getElementById('nationality').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;
          case this.personsTravelling[0].addressLine1 === '':
            document.getElementById('addressLine1').reportValidity();
            document.getElementById('addressLine1').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;
          case this.personsTravelling[0].townCity === '':
            document.getElementById('townCity').reportValidity();
            document.getElementById('townCity').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;
          case this.personsTravelling[0].postcode === '':
            document.getElementById('postcode').reportValidity();
            document.getElementById('postcode').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;  
             
          
          case Object.keys(this.selectedOutBoundJourney).length === 0:
            this.outBoundError = "Please select your outbound journey!";
            document.getElementById('timetable-outbound').scrollIntoView({
              behavior: 'smooth'
            });
            isError = true;
            break;

          case Object.keys(this.selectedReturnJourney).length === 0 && !this.oneWay:
            this.returnError = "Please select your return journey!";
            document.getElementById('timetable-return').scrollIntoView({
              behavior: 'smooth'
            });          
            isError = true;
            break;

          case this.vehicleType === '':
            this.vehicleError = "You must choose an option here";
            document.getElementById('vehicle-select').scrollIntoView({
              behavior: 'smooth'
            });          
            isError = true;
            break;

          case 
            (
              this.vehicleType === 'C=Car' || 
              this.vehicleType === 'M=Campervan Motorhome' ||
              this.vehicleType === 'G=Minibus (multiseat)' ||
              this.vehicleType === 'V=Van'
            ) 
            && this.vehicleSizeLength === '':
              
              this.vehicleError = "You must choose a vehicle size here";
              document.getElementById('vehicle-select').scrollIntoView({
                behavior: 'smooth'
              });          
              isError = true;
              break;
          
          case this.vehicleReg === '':
            this.vehicleError = "You must provide the registration of the vehicle";
            document.getElementById('vehicle-select').scrollIntoView({
              behavior: 'smooth'
            });          
            isError = true;
            break;

          case this.vehicleType === 'F=On foot or cycling' && !this.termsF:
            this.vehicleError = "You must accept the terms if travelling on any journey as a footpassenger!";
            document.getElementById('vehicle-select').scrollIntoView({
              behavior: 'smooth'
            });          
            isError = true;
            break;

          case this.selectedOutBoundJourney.requiresAccommodation === 'Y' && this.selectedOutBoundCabin === '':
            this.outboundCabinError = "Accommodation is required on this route!";
            document.getElementById('cabin-outbound').scrollIntoView({
              behavior: 'smooth'
            });          
            isError = true;
            break;

          case this.selectedReturnJourney.requiresAccommodation === 'Y'  && this.selectedReturnCabin === '' && !this.oneWay:
            this.returnCabinError = "Accommodation is required on this route!";
            document.getElementById('cabin-return').scrollIntoView({
              behavior: 'smooth'
            });          
            isError = true;
            break;          

        }

        if(isError) {
          return;
        }


        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };

          const withCredentials = {
            'withCredentials': true
          };

          let personsTravelling = this.personsTravelling.filter((el, i) => {
            console.log(i, typeof(el?.bookingForOthers), (i === 0 && el?.bookingForOthers !== undefined && el?.bookingForOthers === 'Y'));
            if(i === 0 && el?.bookingForOthers !== undefined && el?.bookingForOthers === 'Y') {
              console.log('skipped', i, el, el.bookingOther);
              return false;
            } else {
              return true;
            }
            
          });

          if(Object.keys(personsTravelling).length < 1) {
            
            this.passengersError = "You must designate at least one person who will be travelling";
            document.getElementById('otherPassengers').scrollIntoView({
              behavior: 'smooth'
            });          
            isError = true;
            return false;
          }

          

          /// set some yes or no's on true or falses....
          ///this.personsTravelling[0].isOrganiser = (this.personsTravelling[0].isOrganiser) ? 'Y' : 'N';

          const data = {
            user_id: this.userId,
            action: 'book',
            offerType: 'IrishFerries',
            offerId: this.offerId,
            organiser: this.personsTravelling[0],
            personsTravelling: personsTravelling,
            pets: this.pets,
            oneWay: (this.oneWay) ? 'Y' : 'N',
            selectedOutBoundJourney: this.selectedOutBoundJourney,
            selectedReturnJourney: this.selectedReturnJourney,
            vehicleType: this.vehicleType,
            vehicleReg: this.vehicleReg,
            vehicleSizeLength: this.vehicleSizeLength,
            hasCaravanTrailer: (this.hasCaravanTrailer) ? 'Y' : 'N',
            caravanTrailerSelection: this.caravanTrailerSelection,
            ExtrasEvCharging: (this.ExtrasEvCharging) ? 'Y' : 'N',
            footpassengerTermsAccepted: (this.termsF) ? 'Y' : 'N',
            outboundCabinSelection: this.selectedOutBoundCabin,
            returnCabinSelection: this.selectedReturnCabin,
            updateMyDetailsAtClub: (this.updateMemberDetailsAtClub) ? 'Y' : 'N'
          };          

          console.log(data, headers, withCredentials);
          
          const url = this.base_url + '/' + config.rest_endpoint + '/offers/';
          axios.post(url,
            data,
            {
              headers: headers
            },
            withCredentials
          ).then((response) => {

              console.log('response', response, response.headers);

              if(response.data.error) {
                this.errors.push(response.data.error);
                ///this.tryLoginAgain = true;
              }
              
              if(response.data.error === undefined){                
                
                console.log('calling emmit');
                /*
                sessionStorage.setItem(
                    "user_data_", ///+response.data.data.user_id
                    JSON.stringify(response.data.data)
                );
                */
                this.bookingSent = true;
                
              }
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }

      },
      back(){
        this.$emit('back');
      }
    },

    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
.card {
  max-width: none;
}
.content table td, .content table th {
  vertical-align: middle;
}
.select,
.routes {
  width: 100%;
}
.is-strikethrough {
  text-decoration:line-through;
  color: #e0e0e0;
}
.tab-content-wrapper {
  position: relative;
}
.tab-content-wrapper .tab-content {
  position: relative;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;

}
.tab-content-wrapper .tab-content.is-active {
  position: absolute;
  z-index: 10;
}
@media screen and (mx-width: 768px) {
  .columns {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>