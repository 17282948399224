<template>
  <header class="heading">
    <h1 class="is-size-3">{{pageTitle}}</h1>
  </header>
  <div class="container is-max-desktop" >
    <div class="columns has-text-left" >
        <div class="column field">
            <label class="label">Filter by Category...</label>
            <div class="control">
                <div class="select">
                <select class="is-wide" v-model="SelectedCategory" >
                    <option value="all" >All Categories</option>
                    <option v-for="(category, catIndex) in categories" :key="catIndex" :value="category.toLowerCase()" >{{category.charAt(0).toUpperCase() + category.slice(1)}}</option>
                </select>
                </div>
            </div>
        </div>
        <div class="column field" >
            <label class="label">Search...</label>
            <div class="control">
                <input class="input" placeholder="i.e. Gunwarf..." v-model="search" />
            </div>
        </div>
    </div>
    <div class="columns is-multiline is-variable is-2" >

        <div class="column is-one-third" v-for="(outing, outingIndex) in thingsToDoFiltered" :key="outingIndex" >
            
            <!-- card -->
            <div class="card" >
                <div class="card-image">
                    <figure class="image is-4by4" >
                        <img :src="outing.images.main" height="150" width="150">              
                    </figure>
                </div>
                <div class="card-content has-text-left pb-0">
                    <div class="media">
                        <div class="media-left">
                            <figure class="image is-48x48">
                            <img :src="outing.images.thumb" alt="Placeholder image">
                            </figure>
                        </div>
                        <div class="media-content">
                            <p class="title is-5">{{outing.post_title}}</p>
                            <p class="subtitle is-6" v-if="outing.at !== ''">{{outing.at}}</p>
                        </div>
                    </div>
                    <div class="content">
                        <p>{{outing.description}}</p>
                    </div>
                    <footer class="card-footer">
                        <a v-for="(link, linkIndex) in outing.links" :key="linkIndex" :href="link" target="_blank" :title="(linkIndex === 'link') ? `View Website` : `Visit ${linkIndex} Page`" class="card-footer-item" aria-label="reply">
                            <span class="icon is-small">
                                <i :class="(linkIndex === 'link') ? `fas fa-globe` : `fa-brands fa-${linkIndex}`" aria-hidden="true"></i>
                            </span>                    
                        </a>                        
                    </footer>
                </div>                
            </div>
            <!-- .card -->

        </div>
    

    </div>    
  </div>
</template>
<script>
  import store from '@/store';
  ///import ThingsToDo from '@/store/things-to-do.json';
  import config from '@/config';
  import axios from 'axios';

  export default {
    props: {
      userId: Number,
      isLoggedIn: Boolean,
      pageId: Number,
      staticPageName: String
    },
    emits: [],
    components: {
      
    },
    store: store,
    data() {
      return {
        pageTitle: '',
        pageContent: '',
        errors: [],
        tryLoginAgain: false,
        offerId: 0,
        ThingsToDo: [],
        thingsToDoFiltered: [],
        SelectedCategory: 'all',
        search: ''
      }
    },
    mounted: function() {
        
    },
    async created() {
        this.getDaysOut();
        
        console.log('categories', this.categories);
    },
    computed: {
      
    },	
    watch:{
        SelectedCategory(newVal){            
            this.SelectedCategory = newVal.toLowerCase();
            console.log('category', this.SelectedCategory);
            const [...arr] = this.ThingsToDo;
            if(this.SelectedCategory !== 'all') {
                this.thingsToDoFiltered = arr.filter(e => [...e.categories].includes(this.SelectedCategory));
            } else {
                this.thingsToDoFiltered = arr;
            }
        },
        search(newVal){
            this.search = newVal.toLowerCase();
            console.log('search', this.search);
            const [...arr] = this.ThingsToDo;
            if(this.search !== '' && this.search.length > 3) {
                this.thingsToDoFiltered = arr.filter(e => {
                    if(e.post_title.toLowerCase().includes(this.search) || e.description.toLowerCase().includes(this.search)) {
                        return true;
                    }
                    
                });
            } else {
                this.thingsToDoFiltered = arr;
            }
        }
    },
    methods: {      
      getDaysOut(){

          try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          
          const data = {};

          console.log('data', data);

          const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
          const url = base_url + '/' + config.rest_endpoint + '/days-out';
          console.log(url);
          axios.get(url,
            data,
            {
              headers: headers
            }
          ).then((response) => {

              console.log('response', response);
              
              if(response.data.error) {
                this.errors.push(response.data.error);
                
              }
              
              if(response.data.error === undefined){     
                           
                console.log('pageContent', response);
                const arr = response.data.data['ThingsToDo'];

                this.ThingsToDo = [...arr];
                console.log('arr', typeof arr, arr);
                this.thingsToDoFiltered = [...arr];

                console.log('pageContentRendered', this.ThingsToDo);

                this.categories = [...new Set([...arr].flatMap(el => el.categories))];
                console.log('this.categories', this.categories);

              }
              
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }

      }
    },
    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
    .select,
    .select .is-wide {
        width: 100%;
    }
</style>