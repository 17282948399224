<template>
  <fieldset>
    <legend class="sub-title py-3" >Main Contact Details (The Organiser)<br />
    <span class="has-text-danger is-size-7" >Please fill in all the details so that Irish Ferries can contact you directly even if you are not travelling:</span></legend>
    <div class="box has-background-primary" >
      <p class="is-pulled-right" ><i class="fa-solid fa-person-burst mr-2"></i></p>
      <p class="subtitle"><label for="bookingOther" >I am booking on behalf of somebody else/or other people & not travelling: </label><input type="checkbox" id="bookingOther" :value="bookingOther" @click="bookingOther = ($event.target.checked) ? true : false; updateMember()" ></p>
      <div :class="(bookingOther) ? '' : ''">
      <p><label for="fn" >My First Name *: </label><input id="fn" class="input" type="text" placeholder="First Name" :value="fn" @input="fn = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="ln" >My Last Name *: </label><input id="ln" class="input" type="text" placeholder="Last Name" :value="ln" @input="ln = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="phone" >Phone *: </label><input id="phone" class="input" type="text" placeholder="Phone Number" :value="phone" @input="phone = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="email" >Email (*** please use your actual email, not the club generated email ***): </label><input id="email" class="input" type="text" placeholder="Email or Username" :value="em" @input="em = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="nationality" >Nationality *: </label><input id="nationality" class="input" type="text" placeholder="ie... United Kingdom" :value="nationality" @input="nationality = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="dob" >Date of Birth (in (dd/mm/yyyy format) *: </label><input id="dob" class="input" type="text" placeholder="ie... 01/10/1970" :value="dob" @input="dob = $event.target.value; updateMember()" required="required" ></p>
      
      <p><label for="addressLine1" >Address Line 1*</label><input id="addressLine1" class="input" type="text" placeholder="ie... 12 A Street" :value="addressLine1" @input="addressLine1 = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="addressLine2" >Address Line 2</label><input id="addressLine2" class="input" type="text" :value="addressLine2" @input="addressLine2 = $event.target.value; updateMember()" ></p>
      <p><label for="addressLine3" >Address Line 3</label><input id="addressLine3" class="input" type="text" :value="addressLine3" @input="addressLine3 = $event.target.value; updateMember()" ></p>
      <p><label for="townCity" >Town/City *</label><input id="townCity" class="input" type="text" :value="townCity" @input="townCity = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="county" >County</label><input id="county" class="input" type="text" :value="county" @input="county = $event.target.value; updateMember()" ></p>
      <p><label for="postcode" >Postcode *</label><input id="postcode" class="input" type="text" :value="postcode" @input="postcode = $event.target.value; updateMember()" required="required" ></p>
      <p><label for="country" >Country</label><input id="country" class="input" type="text" :value="country" @input="country = $event.target.value; updateMember()" ></p>
      
      <p><label for="isWheelChairUser" >I use a wheelchair: </label><input id="isWheelChairUser" class="checkbox" type="checkbox" :value="isWheelChairUser" @input="isWheelChairUser = ($event.target.checked) ? true : false; updateMember()" ></p>
      <p><textarea id="specialRequirements" class="textarea otherPersons" placeholder="I.E. Diffculty walking, requires wheelchair access etc" :value="requirements" @input="requirements = $event.target.value; updateMember()" ></textarea></p>
      <p><label for="updateDetailsAtClub" >Update my phone, DOB and email on the club's database to these details</label> <input id="updateDetailsAtClub" class="checkbox ml-2" type="checkbox" v-model="updateDetailsAtClub" ></p>
      </div>
    </div>
  </fieldset>
  <fieldset id="otherPassengers" >
    <legend class="sub-title py-3" >Other Persons Travelling:</legend>
    <p v-if="errorMessage !== ''" :id="`passengers-error`" class="has-background-danger p-3 has-text-white is-size-4" >** {{errorMessage}} **</p>
    <div class="box has-background-warning otherUsers" >
      <p class="is-pulled-right" ><i class="fa-solid fa-person-walking-luggage mr-2"></i></p>
      <p><label for="addOtherFn" >First Name(s) *: </label><input id="addOtherFn" class="input otherPersons" type="text" placeholder="First Names" required="required" ></p>
      <p><label for="addOtherLn" >Last Names *: </label><input id="addOtherLn" class="input otherPersons" type="text" placeholder="Last Name" required="required" ></p>
      <p><label for="addOtherNationality" >Nationality *: </label><input id="addOtherNationality" class="input otherPersons" type="text" placeholder="ie... United Kingdom" required="required" ></p>
      <p><label for="addOtherDOB">Date of Birth (dd/mm/yyyy format) *: </label><input id="addOtherDOB" class="input otherPersons" type="text" placeholder="ie... 01/10/1970" required="required" ></p>      
      <p><label for="addOtherWheelChair" >is Wheelchair User: </label><input id="addOtherWheelChair" class="checkbox otherPersons" type="checkbox" ></p>
      <p><label for="addOtherSpecial" >Special Requirements</label></p>
      <p><textarea id="addOtherSpecial" class="textarea otherPersons" placeholder="I.E. Diffculty walking, requires wheelchair access etc" ></textarea></p>
      <a v-if="!isEditingPerson" class="button is-info" data-target="otherUsers" @click="addPerson" >+ Add Person</a>
      <a v-if="isEditingPerson" class="button is-primary" data-target="otherUsers" @click="addPerson" >Save</a>
    </div>
    <p class="subtitle"><i class="fa-solid fa-family mr-2"></i>Passengers list:</p>
    <div class="other_persons_list is-size-7" >
      <table class="table" >
        <thead>
          <tr>
            <th scope="col" width="160" ><abbr title="First Names">Name</abbr></th>
            <th scope="col" width="100" ><abbr title="DOB">DOB</abbr></th>
            <th scope="col" align="center" ><abbr title="Nationality">N</abbr></th>
            <th scope="col" align="center" ><abbr title="Wheel Chair User"><i class="fa-solid fa-wheelchair"></i></abbr></th>
            <th align="center" width="50" ><i class="fa-solid fa-pen-to-square"></i></th>
            <th align="center" width="50" ><i class="fa-solid fa-delete-left"></i></th>
          </tr>
        </thead>
        <tfoot class="has-text-lighter">
          <tr>
            <th scope="col" ><abbr title="First Names">Name</abbr></th>
            <th scope="col" ><abbr title="DOB">DOB</abbr></th>
            <th scope="col" width="50" align="center" ><abbr title="Nationality">N</abbr></th>
            <th scope="col" width="50" align="center" ><abbr title="Wheel Chair User"><i class="fa-solid fa-wheelchair"></i></abbr></th>
            <th align="center" width="50" ><i class="fa-solid fa-pen-to-square"></i></th>
            <th align="center" width="50" ><i class="fa-solid fa-delete-left"></i></th>
          </tr>
        </tfoot>
        <tbody v-for="(person, index) in personsTravelling" :key="index" :class="(person?.isOrganiser && person?.bookingForOthers === 'Y') ? 'is-strikethrough' : ((person?.isOrganiser) ? 'has-background-primary' : '') + ' mt-3 border-top'" >
          <tr>
            <td>{{person.fn}} {{person.ln}}</td>
            <td><span class="mr-2" v-html="getAgeIcon(person.age)"></span>{{person.dob}}</td>
            <td align="center" >{{person.nationality}}</td>
            <td align="center" ><span v-html="getWheelChairIcon(person.isWheelChairUser)"></span></td>
            <td align="center" ><a v-if="!person?.isOrganiser" class="button is-small is-info" title="remove person from this list" @click="editPerson(index)" ><i class="fa-solid fa-pen-to-square"></i></a></td>
            <td align="center" ><a v-if="!person?.isOrganiser" class="button is-small is-danger" title="remove person from this list" @click="removePerson(index)" ><i class="fa-solid fa-delete-left"></i></a></td>
          </tr>
          <tr v-if="person.requirements !== ''" >
            <th scope="row" ><abbr title="Special Requirements" ><i class="fa-solid fa-syringe"></i></abbr></th>          
            <td colspan="5" >{{person.requirements}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </fieldset>
</template>
<script>
  import store from '@/store';
  import he from 'he'; 

  export default {
    props: {
      userId: Number,
      errorMessage: String
    },
    emits: ["updatePassengerList", "updateMyDetailsAtClub"],
    components: {          
    },
    store: store,
    data() {
      return {
        base_url: '',
        user_id: 0,
        user: {},
        fn: '',
        ln: '',        
        em: '',
        pw: '',
        pwConfirm: '',
        dob: '',
        phone: '',
        nationality: '',
        requirements: '',
        isWheelChairUser: false,
        bookingOther: false,
        updateDetailsAtClub: false,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        townCity: '',
        county: '',
        postcode: '',
        country: '',
        user_original_details:{},
        loadingTimeOut: 3600,
        personsTravelling: [
          
        ],
        currentPerson: -1,
        errors: [],
        oneWay: false,
        tryLoginAgain: false,
        isEditingPerson: false,
        currentMembership: {}

      }
    },
    mounted: function() {      
      
    },
    async created() {

      this.loadMember();
      
    },
    computed: {
      form_random_key(){
        return this.setUpId + '_' + this.randomCode();
      },
      form_random_keyCheck(){
        return this.captchaCode();
      }
    },
    watch: {
      bookingOther(newVal){
        this.personsTravelling[0]['bookingForOthers'] = (newVal) ? 'Y' : 'N';
      },
      updateDetailsAtClub(){
        this.updateMyDetailsAtClub();
      }
    },	
    methods: {
      
      captchaCode(length = 8) {
          const possible_letters = '23456789bcdfghjkmnpqrstvwxyz';
          /*const random_dots = 0;
          const random_lines = 20;*/
          let code = '';
          for (let i = 0; i < length; i++) {
              let rand = Math.round(Math.random(0, possible_letters.length - 1) * 10);
              code += possible_letters[rand];
          }
          return code;
      },
      randomCode: function(length = 10) {
          const charLength = Math.round(length * 0.8);
          const numLength = Math.round(length * 0.2);
          const keywords = [
              {
                "count": charLength,
                "char": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
              },
              {
                "count": numLength,
                "char": "0123456789"
              }
            ];
          
          const temp_array = keywords.map(function(char_set) {
              let n_array = [];
              for (let i = 0; i < char_set['count']; i++) {
                n_array.push(char_set['char'][Math.round(Math.random(0, char_set['char'].length - 1) * 10)]);
              }
              return n_array.join('');
          }); 
          return temp_array.join('');
      },      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      clearOtherPersonsFromForm(){
        
        const otherPersons = document.querySelectorAll('.otherPersons');
        [...otherPersons].forEach((el) => {
          if(el.type && el.type === 'checkbox') {
            el.checked = false;
          } else {
            el.value = '';
          }

        });

      },
      getAgeIcon(age){
        let icon = '';
        switch(true){
          case age <= 3:
            icon = '<i class="fa-solid fa-baby"></i>';
            break;
          case age <= 15:
            icon = '<i class="fa-solid fa-child"></i>';
            break;
          default:
            icon = '<i class="fa-solid fa-person"></i>';
        }

        return icon;

      },
      getWheelChairIcon(wheelchair){
        let icon = '';
        switch(true){
          case wheelchair === 'Y':
            icon = '<i class="fa-solid fa-wheelchair"></i>';
            break;
          default:
            icon = '';
        }
        return icon;

      },

      calcAge(dob) {
        console.log('dob', dob);
        if(!dob) {
          return false;
        }
        const now = new Date();
        const birthdate = dob.split("/");
        const born = new Date(birthdate[2], birthdate[1]-1, birthdate[0]);
        const birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
        if (now >= birthday) {
          return now.getFullYear() - born.getFullYear();
        } else {
          return now.getFullYear() - born.getFullYear() - 1;
        }
      },

      // TODO: Move to Persons Componenet & emit
      addPerson(){

        const fn = document.getElementById('addOtherFn');
        const ln = document.getElementById('addOtherLn');
        const dob = document.getElementById('addOtherDOB');
        console.log('dob...', dob);
        const age = this.calcAge(dob.value);
        console.log('age', age);
        const nationality = document.getElementById('addOtherNationality');
        const wheelchair = document.getElementById('addOtherWheelChair');
        const requirements = document.getElementById('addOtherSpecial');
        let isError = false;
        
        switch(true){
          case fn.value === '':
            fn.reportValidity();
            isError = true;
            break;
          case ln.value === '':
            ln.reportValidity();
            isError = true;
            break;
          case dob.value === '':
            dob.reportValidity();
            isError = true;
            break;
          case nationality.value === '':
            nationality.reportValidity();
            isError = true;
            break;
        }

        if(isError) {
          return;
        }

        if(this.currentPerson <= 0){
          this.personsTravelling.push({
            fn: fn.value,
            ln: ln.value,
            dob: dob.value,
            age: age,
            isUnder15: (age<=15) ? 'Y' : 'N',
            isInfant: (age<3) ? 'Y' : 'N',
            nationality: nationality.value,
            isWheelChairUser: (wheelchair.checked) ? 'Y' : 'N',
            requirements: requirements.value
          });
        } else {
          this.personsTravelling[this.currentPerson] = {
            fn: fn.value,
            ln: ln.value,
            dob: dob.value,
            age: age,
            isUnder15: (age<=15) ? 'Y' : 'N',
            isInfant: (age<3) ? 'Y' : 'N',
            nationality: nationality.value,
            isWheelChairUser: ((wheelchair.checked) ? 'Y' : 'N'),
            requirements: requirements.value
          };
          this.currentPerson = -1;
          this.isEditingPerson = false;
        }

        this.$emit('updatePassengerList', this.personsTravelling);

        /// Clear Form...
        this.clearOtherPersonsFromForm();


      },
      // TODO: Move to Persons Componenet & emit
      removePerson(index){
        let remP = confirm("Are you sure you want to remove this person from the list?");
        if(remP) {
          this.personsTravelling = this.personsTravelling.filter( (el, i) => index !== i);
        }
        this.$emit('updatePassengerList', this.personsTravelling);
      },
      // TODO: Move to Persons Componenet & emit
      editPerson(index){
        const editing = this.personsTravelling[index];
        this.currentPerson = index;

        document.getElementById('addOtherFn').value = editing.fn;
        document.getElementById('addOtherLn').value = editing.ln;
        document.getElementById('addOtherDOB').value = editing.dob;
        document.getElementById('addOtherNationality').value = editing.nationality;
        document.getElementById('addOtherWheelChair').checked = (editing.isWheelChairUser === 'Y') ? true : false;
        document.getElementById('addOtherSpecial').value = editing.requirements;
        
        this.isEditingPerson = true;

        this.$emit('updatePassengerList', this.personsTravelling);

      },
      // TODO: Move to Persons Componenet & emit
      loadMember(){
        
        this.user_original_details = this.user = store.state.user;
        this.user_id = this.userId;
        this.memberNumber = this.user_id;
        //this.fn = this.user?.name.split(':')[0];
        //this.ln = this.user?.name.split(':')[1];
        this.fn = this.user?.first_name;
        this.ln = this.user?.last_name;
        this.dob = (this.user?.dob) ? this.user?.dob : this.dob;
        this.em = this.user?.email;
        this.pw = this.user?.pw;
        this.isWheelChairUser = (this.user?.isWheelChairUser) ? this.user?.isWheelChairUser : this.isWheelChairUser;
        this.currentMembership = this.user?.current_plan;
        
        this.addressLine1 = (this.user?.addressLine1) ? this.user?.addressLine1 : this.addressLine1;
        this.addressLine2 = (this.user?.addressLine2) ? this.user?.addressLine2 : this.addressLine2;
        this.addressLine3 = (this.user?.addressLine3) ? this.user?.addressLine3 : this.addressLine3;
        this.townCity = (this.user?.townCity) ? this.user?.townCity : this.townCity;
        this.county = (this.user?.county) ? this.user?.county : this.county;
        this.postcode = (this.user?.postcode) ? this.user?.postcode : this.postcode;
        this.country = (this.user?.country) ? this.user?.country: this.country;

        console.log('this.user..n', this.user);
        const age = this.calcAge(this.dob);

        this.personsTravelling.push({
          isOrganiser: 'Y',
          fn: this.fn,
          ln: this.ln,        
          em: this.em,
          dob: this.dob,
          age: age,
          isUnder15: (age<=15) ? 'Y' : 'N',
          isInfant: (age<3) ? 'Y' : 'N',
          phone: this.phone,
          nationality: this.nationality,
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          addressLine3: this.addressLine3,
          townCity: this.townCity,
          county: this.county,
          postcode: this.postcode,
          country: this.country,
          isWheelChairUser: (this.isWheelChairUser) ? 'Y' : 'N',
          requirements: this.requirements,
          bookingForOthers: (this.bookingOther) ? 'Y' : 'N'
        });

        this.$emit('updatePassengerList', this.personsTravelling);

      },
      // TODO: Move to Persons Componenet & emit
      updateMember(){

        ///let isError = false;
        ///console.log(this.dob.match(/\d{2}\/\d{2}\/\d{4}/));
        /** Don't check here causes annoyance       
        switch(true){
          case this.fn === '':
            document.getElementById('fn').reportValidity();
            isError = true;
            break;
          case this.ln === '':
            document.getElementById('ln').reportValidity();
            isError = true;
            break;
          case this.em === '' || (this.em.indexOf('portsmouthirishclub.org') !== -1):
            document.getElementById('email').setCustomValidity("The email connot be blank or be a Portsmouth Irish club email");
            document.getElementById('email').reportValidity();
            isError = true;
            break;
          case this.phone === '':
            document.getElementById('phone').reportValidity();
            isError = true;
            break;
          case this.dob === '':
            document.getElementById('dob').reportValidity();
            isError = true;
            break;
          case this.addressLine1 === '':
            document.getElementById('addressLine1').reportValidity();
            isError = true;
            break;
          case this.townCity === '':
            document.getElementById('townCity').reportValidity();
            isError = true;
            break;
          case this.postcode === '':
            document.getElementById('postcode').reportValidity();
            isError = true;
            break;
          
          case this.nationality === '':
            document.getElementById('nationality').reportValidity();
            isError = true;
            break;
        }
          

        if(isError) {
          return;
        }
        */
        
        console.log(this.dob);
        const age = this.calcAge(this.dob);

        this.personsTravelling[0] = {
          isOrganiser: 'Y',
          fn: this.fn,
          ln: this.ln,        
          em: this.em,
          dob: this.dob,
          age: age,
          isUnder15: (age<=15) ? 'Y' : 'N',
          isInfant: (age<3) ? 'Y' : 'N',
          phone: this.phone,
          nationality: this.nationality,
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          addressLine3: this.addressLine3,
          townCity: this.townCity,
          county: this.county,
          postcode: this.postcode,
          country: this.country,
          requirements: this.requirements,
          isWheelChairUser: (this.isWheelChairUser) ? 'Y' : 'N',
          bookingForOthers: (this.bookingOther) ? 'Y' : 'N'
        };

        this.$emit('updatePassengerList', this.personsTravelling);

      },
      updateMyDetailsAtClub(){
        this.$emit('updateMyDetailsAtClub', this.updateDetailsAtClub);
      }

    },

    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
.card {
  max-width: none;
}
.content table td, .content table th {
  vertical-align: middle;
}
tbody.border-top {
  border-top: 1px solid #eee;
}
tbody.border-top td {
  border: none;
}
tbody.border-top:nth-child(even) {
  background: #eee;
}
.select,
.routes {
  width: 100%;
}
.is-strikethrough {
  text-decoration:line-through;
  color: #e0e0e0;
}
@media screen and (mx-width: 768px) {
  .columns {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>