import { createApp } from 'vue'
///import { routes } from './routes'
import { store } from './store'

//import { config } from './config'
import App from './App.vue'

import './registerServiceWorker'

/*
import { StripePlugin } from '@vue-stripe/vue-stripe';
*/
const options = {
  ///pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  ///sk: process.env.VUE_APP_STRIPE_SECRET_KEY,
  ///stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: process.env.VUE_APP_API_VERSION,
  locale: process.env.VUE_APP_LOCALE,
};


const app = createApp(App)
app.config.globalProperties.picOptions = options;
///app.use(routes)
app.use(store)
app.use(options)
app.mount('#app')


