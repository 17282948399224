<template>
  <header class="heading">
    Membership Card
  </header>
    <div v-if="imgLoaded" class="card" ><!-- v-if="!isStream" -->

      <div class="card-image">
        <figure class="image is-4by4">
          <img class="qr-code" :src="memberQRCode" />
        </figure>
      </div>

      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <img alt="Ireland Flag" src="assets/logo.png"> 
            </figure>
          </div>        
          <div class="media-content has-text-left">
            <p class="title is-4">{{memberName}}</p>
            <p class="subtitle is-6">No: {{memberNumber}}<br />Status : {{memberStatus}}</p>
          </div>
        </div>
        <div class="content has-text-left">
          <p class="subtitle is-6 mb-0"><strong>DOB:</strong> <span class="has-text-right is-align-items-flex-end">{{memberDOB}}</span></p>
          <p class="subtitle is-6 has-text-left"><strong>Renewal Date:</strong> <span class="has-text-right is-align-items-flex-end" >{{memberRenewalDate}}</span></p>
        </div>
        <!--<a href="" @click="logOut" >Logout</a>-->
      </div>
    </div>
</template>
<script>
  import store from '@/store';
  import he from 'he'; 
  
  export default {
    props: {
      userId: Number
    },
    emmits: ["logOut"],
    components: {
      
    },
    data() {
      return {
        memberNumber: 0,
        memberName: '',
        memberDOB: '',
        memberEmail: '',
        memberRegistered: '',
        memberRenewalDate: '',
        memberStatus: '',
        memberQRCode: '',
        imgLoaded: false,
        errors: [],
        isLoggedIn: store.state.isLoggedIn
      }
    },
    mounted: function() {
      console.log('mounted', this);
      this.loadMember();
    },
    async created() {

      console.log('loaded',  this);
      /*
      try {
        ///const response = await axios.get('/data/tracks.json')
        
        const response = await axios.get('https://portsmouthirishsociety.egbx.uk/wp-json/Portsmouth_Irish_Society/v2/member/'+this.user_id);
        console.log(response);
        this.imgLoaded = true;
        this.loadMember(response.data.data);
      } catch (e) {
        this.errors.push(e)
      }
      */
      
      this.imgLoaded = true;

    },
    computed: {
      
    },	
    methods: {
      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      loadMember(){
        this.user = store.state.user;
        this.memberNumber = this.user?.user_id;
        this.memberName = this.user?.name;
        this.memberDOB = this.user?.dob;
        this.memberEmail = this.user?.email;
        this.memberRegistered = this.user?.user_registered;
        this.memberRenewalDate = this.user?.renewal_date;
        this.memberStatus = this.user?.primary_status;
        this.memberQRCode = this.user?.qr_code;
      },
      logOut(){
        this.$emit('logOut');
      }
    },
    watch: {
      currentTime: function() {
        
      }
    },
    beforeUnmount: function() {
      
    }    
  }
</script>