<template>
  <header class="heading" >
    <h1 class="is-size-3">{{pageTitle}}</h1>
  </header>
  <div class="container is-max-desktop" v-html="pageContent"></div>
</template>
<script>
  import store from '@/store';
  import config from '@/config';
  import axios from 'axios';
  import he from 'he'; 

  export default {
    props: {
      pageId: Number,
      membersPageId: Number
    },
    components: {
      
    },
    store: store,
    data() {
      return {
        pageTitle: '',
        pageContent: '',
        errors: [],
        tryLoginAgain: false
      }
    },
    mounted: function() {
      console.log('clubPageMounted');
      this.getPage();
    },
    async created() {
      console.log('clubPageMounted');
      
    },
    computed: {
      
    },
    watch: {
      pageId(newId) {
        console.log('clubPagewatchers', newId);
        //this.pageId = newId;
        this.getPage();
      },
      membersPageId(newId) {
        console.log('membersPageIdwatchers', newId);
        //this.membersPageId = newId;
        this.getMembersPage();
      }
    },
    methods: {
      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      getPage(){
        console.log('called getPage', this.pageId, this.membersPageId, this, typeof(this.pageId), typeof(this.pageId) === 'number'); 

        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          
          const data = {id: this.pageId};
          const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
          const endpoint_page = (this.membersPageId) ? config.endpoints.members_pages + '/' + this.membersPageId : config.endpoints.pages + '/' + this.pageId;
          const url = base_url + '/' + config.wp_rest_endpoint + '/' + endpoint_page;
          console.log(url);
          axios.get(url,
            data,
            {
              headers: headers
            }
          ).then((response) => {

              console.log('response', response);
              
              if(response.data.error) {
                this.errors.push(response.data.error);
                
              }
              
              if(response.data.error === undefined){                
                console.log('pageContent', response); 
                this.pageTitle = response.data.title.rendered;
                this.pageContent = response.data.content.rendered.replace('wp-block-columns has-2-columns', 'columns p-3 mb-1').replaceAll('wp-block-column', 'column');
                console.log('pageContentRendered', this); 
              }
              
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }      

      },
      getMembersPage(){
        console.log('called getPage', this.pageId, this, typeof(this.pageId), typeof(this.pageId) === 'number'); 

        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          
          const data = {id: this.pageId};
          const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
          const url = base_url + '/' + config.wp_rest_endpoint + '/' + config.endpoints.pages + '/' + this.pageId;
          console.log(url);
          axios.get(url,
            data,
            {
              headers: headers
            }
          ).then((response) => {

              console.log('response', response);
              
              if(response.data.error) {
                this.errors.push(response.data.error);
                
              }
              
              if(response.data.error === undefined){                
                console.log('pageContent', response); 
                this.pageTitle = response.data.title.rendered;
                this.pageContent = response.data.content.rendered.replace('wp-block-columns has-2-columns', 'columns p-3 mb-1').replaceAll('wp-block-column', 'column');
                console.log('pageContentRendered', this); 
              }
              
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }      

      },
      forgotPassword(){
        this.$emit('forgotPassword');
      },
      register(){
        this.$emit('register');
      }
    },

    beforeUnmount: function() {
      
    }    
  }
</script>