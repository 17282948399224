<template>
  <header class="heading">
    <h1 class="is-size-3">{{pageTitle}}</h1>
  </header>
  <div class="container is-max-desktop" >
    <div class="columns is-multiline is-variable is-4" >
      <div class="column is-one-third" v-for="(feed, fIndex) in feedItems" :key="fIndex" >    
        <div class="card" >
          <div class="card-image">
            <figure class="image is-4by4" v-html="feed.img">                
            </figure>
          </div>
          <div class="card-content has-text-left pb-0">
            <p class="title is-5">{{feed.title}}</p>
            <p class="subtitle is-7">{{feed.pubDate}}</p>
            <p>{{feed.description}}</p>
          </div>
          <footer class="card-footer">
            <a :href="feed.link" target="_blank" title="view Article" class="card-footer-item" aria-label="reply">
              <span class="icon is-small">
                <i class="fas fa-link" aria-hidden="true"></i>
              </span>
            </a>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import store from '@/store';
  ///import axios from 'axios';
  
  import he from 'he';
  
  export default {
    props: {
      userId: Number,
      isLoggedIn: Boolean,
      pageId: Number,
      staticPageName: String
    },
    emits: [""],
    components: {
      
    },
    store: store,
    data() {
      return {
        pageTitle: 'Latest News from RTÉ',
        pageContent: '',
        rssUrl: 'https://portsmouthirishclub.org/wp-content/plugins/portsmouth-irish-society/logs/rtenews.xml',
        name: "",
        limit: 5,
        feedItems: [],
        errors: [],
        tryLoginAgain: false
      }
    },
    mounted: function() {
      
      this.getNews();

    },
    async created() {
      
    },
    computed: {
      /*chunkedFeedItems() {
        return chunk(this.feedItems, 3)
      }*/
    },	
    methods: {
      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      async getNews(){
        console.log('called News', this.userId, this.pageId, this.staticPageName);

        try {

          /*const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              ///'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          
          const data = {};

          console.log('data', data);*/

          /*const urlRegex = '/(http|ftp|https)://[w-]+(.[w-]+)+([w.,@?^=%&amp;:/~+#-]*[w@?^=%&amp;/~+#-])?/';
          if (!urlRegex.test(this.rssUrl)) {
            return;
          }*/
          
          const res = await fetch(
            `https://api.allorigins.win/get?url=${this.rssUrl}`
          );
          const { contents } = await res.json();
          console.log(contents);
          const feed = new window.DOMParser().parseFromString(contents, "text/xml");
          console.log(feed);
          const items = feed.querySelectorAll("item");
          
          this.feedItems = [...items].map((el) => {
            
            const imgAtts = el.getElementsByTagName("media:content");
            const dt = new Date(el.querySelector("pubDate").innerHTML);

            const year  = dt.getFullYear();
            const month = (dt.getMonth() + 1).toString().padStart(2, "0");
            const day   = dt.getDate().toString().padStart(2, "0");

            const v =  {
              link: el.querySelector("link").innerHTML,
              title: el.querySelector("title").innerHTML,
              description: el.querySelector("description").innerHTML,
              pubDate: day + '/' + month + '/' + year,
              category: el.querySelector("category").innerHTML,
              img: `<img src="${imgAtts[0].attributes[2].textContent}" width="${imgAtts[0].attributes[3].textContent}" height="${imgAtts[0].attributes[0].textContent}" />`
            };

            return v;

          });

          console.log('items', this.feedItems);
          
          /*axios.get(url,
            data,
            {
              headers: headers
            }
          ).then((response) => {

              console.log('response', response);
              
              if(response.data.error) {
                this.errors.push(response.data.error);
                
              }
              
              if(response.data.error === undefined){  

                console.log('pageContent', response);                
                  
                this.pageTitle = 'RTE News';
                //this.pageContent = response.data.content.rendered.replace('wp-block-columns has-2-columns', 'columns p-3 mb-1').replaceAll('wp-block-column', 'column');
                this.pageContent = null;


                console.log('pageContentRendered', this);

              }
              
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });*/
          
        } catch (e) {
          this.errors.push(e)
        } 

      },
      displayOffer(offer_id){
        this.getOffers(offer_id);
      },
    },
    beforeUnmount: function() {
      
    }    
  }
</script>