<template>
    <Datepicker :uid="uid" v-model="date" :placeholder="title" :format-locale="enGB" format="dd/LL/uu" @update:modelValue="setDate" ></Datepicker>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { enGB } from 'date-fns/locale';
    
export default {
    props: {
      title: String,
      uid: String
    },
    emits: ["filterDateTravel"],
    components: { Datepicker },
    data() {
        return {
            date: null,
            enGB
        };
    },
    mounted(){
      console.log('title', this.title);
    },
    methods: {
      setDate(value){
          this.date = value;
          console.log('date selected... now emit', this.date);
          this.$emit('filterDateTravel', this.date);
      }
    }
}
</script>