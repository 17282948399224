<template>
  <header class="heading">
    Log In
  </header>
    <div class="card" ><!-- v-if="!isStream" -->
      <div class="card-content">        
        <div class="content has-text-left">
          <form>
            <span v-if="passwordResetSent" class="has-text-primary is-size-7" >Password Reset Sent!</span>
            <p><label for="email" >Reset My Password: </label><input id="email" class="input" type="text" placeholder="Email or Username" v-model="em" ></p>            
            <p class="mb-7"><button class="button is-success is-fullwidth" @click="sendReminder" >Send Reminder</button></p>
            <p class="is-flex is-justify-content-space-around is-align-content-center" ><a class="is-warning" @click="back" >Back</a></p>
          </form>
        </div>
      </div>
    </div>
</template>
<script>
  import store from '@/store';
  import config from '@/config';
  import axios from 'axios';
  import he from 'he'; 
  
  export default {
    props: {
      
    },
    emits: ["back"],
    components: {
      
    },
    store: store,
    data() {
      return {
        em: '',
        passwordResetSent: false,
        errors: []
      }
    },
    mounted: function() {
    },
    async created() {
      
    },
    computed: {
      
    },	
    methods: {
      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      sendReminder(e){
        e.preventDefault()
        console.log('called sendReminder', this, this.em);

        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          const data = {user_login: this.em, user_password: this.pw};
          const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
          const url = base_url + '/' + config.rest_endpoint + '/' + config.endpoints.forgot_password;
          axios.post(url,
            data,
            {
              headers: headers
            }
          ).then((response) => {

              console.log('response', response.data);

              if(response.data.error) {
                this.errors.push(response.data.error);
                
              }
              
              if(response.data.error === undefined){                
                console.log('calling emmit');
                this.passwordResetSent = true;                                     
                
              }
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }

      },
      back(){
        this.$emit('back');
      }
    },

    beforeUnmount: function() {
      
    }    
  }
</script>