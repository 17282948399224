<template>
  <div :id="`timetable-${uid}`" :class="`box ${background} has-text-white ${uid}`" >
    <div class="columns mb-4">
      <div class="column">
        <h4 class="title has-text-white">{{Title}} <i class="is-pulled-right fa-solid fa-ship mr-2"></i></h4>
        <p v-if="errorMessage !== ''" :id="`timetable-${uid}-error`" class="has-background-warning p-3 has-text-dark is-size-4" >** {{errorMessage}} **</p>
      </div>
                      
    </div>
    
    <div class="columns" >
      <div class="column">
        <p><label :for="`${uid}-date`" >Date...</label></p>
        <DatePicker :uid="uid" :title="`Select your ${uid} date`"  @filterDateTravel="filterDateTravel" />
      </div>
      <div class="column" >
        <p><label :for="`${uid}-country`" >From/To...</label></p>
        <select :id="`${uid}-country`" class="select is-wide" v-model="RouteOfTravel" @change="filterCountry()" >
          <option value="">...Select</option>
          <option v-for="(value, index) in IrishFerriesTimetable" :key="index" :value="index" >{{index}}</option>
        </select>
      </div>
      <div class="column" >
        <p><label :for="`${uid}-direction`" >Direction...</label></p>
        <select :id="`${uid}-direction`" class="select is-wide" v-model="RouteDirection" @change="filterDirection()" >
          <option value="">...Select</option>
          <option v-for="(value, index) in IrishFerriesTimetableDirection" :key="index" :value="index" >{{index}}</option>
        </select>
      </div>                                 
    </div>
    <!-- .list -->
    <div :class="`${uid}`" >
      <p><label :for="`${uid}-time`" >Select your {{uid}} journey...</label></p>
                            
      <div v-for="(dates, dateIndex) in filteredDatesDisplayed" :key="dateIndex" >
        
        <div v-for="(sailings, sailingsIndex) in dates" :key="sailingsIndex" >
          
          <div v-for="(sailing, sailingIndex) in sailings" :key="sailingIndex" >
            
            <div class="mb-6" v-for="(options, optionsIndex) in sailing" :key="optionsIndex" >
              
              <div class="is-flex is-justify-content-space-between is-align-items-flex-end" >
                <p class="mb-0">{{optionsIndex}}</p>
                <p class="is-size-7 mb-0" ><i class="fa-solid fa-person-walking-luggage mr-2"></i> Footpassengers Allowed <i class="fa-solid fa-bed ml-2 mr-2"></i> Requires Accommodation Booking</p>
                <p class="has-text-right mb-0" >{{options[Object.keys(options)[0]].day}} {{sailingsIndex}}</p>
              </div>

              <div class="box card mb-3" v-for="(option, optionIndex) in options" :key="optionIndex" >
                
                <header class="card-header columns is-size-7">
                  <div class="column" >{{sailingsIndex}}</div>                                
                  <div class="column" >{{sailingIndex}}</div>
                  <div class="column" >{{optionsIndex}}</div>
                  <div class="column" >{{optionIndex}}</div>                                
                </header>
                <table class="table is-full-width is-hoverable is-striped is-size-7" >
                  <thead>
                    <th>depart</th>
                    <th>arrive</th>
                    <th><abbr title="Footpassenger are allowed" ><i class="fa-solid fa-person-walking-luggage has-text-grey-dark"></i></abbr></th>
                    <th><abbr title="Accommodation Cabin is required on this sailing" ><i class="fa-solid fa-bed has-text-grey-dark"></i></abbr></th>
                    <th width="180" class="has-text-centered" >Select {{Title}}</th>
                  </thead>
                  <tbody>
                    <tr v-for="(opt, optIndex) in option['options']" :key="optIndex" >                      
                      <td>{{opt.d}}</td>
                      <td>{{opt.a}}</td>
                      <td><span class="has-text-primary" v-html="getRequiresFpIcon(opt.fp)" ></span></td>
                      <td><span class="has-text-danger" v-html="getRequiresAccIcon(option.requiresAccommodation)"></span></td>
                      <!--{{sailingsIndex}}-{{sailingIndex}}-{{optionsIndex}}-{{optionIndex}}-{{opt.d}}-->
                      <td class="has-text-centered" >
                        <label style="display:table;width:100%;" for="`${sailingsIndex}-${sailingIndex}-${optionsIndex}-${optionIndex}-${opt.d}`" >
                          <input type="radio" 
                            :id="`${sailingsIndex}-${sailingIndex}-${optionsIndex}-${optionIndex}-${opt.d}`" 
                            :name="`${uid}-departs`" 
                            :data-fp="`${(opt.fp ? 'Y' : 'N')}`" 
                            :data-departs="`@${opt.d}`" 
                            :data-sailingDate="`${sailingsIndex}`" 
                            :data-route="`${sailingIndex}`" 
                            :data-from="`${optionsIndex}`" 
                            :data-ferry="`${optionIndex}`" 
                            :data-requiresAccommodation="`${(option.requiresAccommodation ? 'Y' : 'N')}`"
                            @click="selectSailing"
                          />
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- .list --> 
    
    <nav class="pagination has-background-light p-2" role="navigation" aria-label="pagination">
      <a v-if="thisPage > 1" class="pagination-previous" @click="paginate(undefined, 'prev')" >Previous</a>
      <a v-if="thisPage < lastPage" class="pagination-next" @click="paginate(undefined, 'next')"  >Next page</a>
      <ul class="pagination-list m-0">
        <li>
          <a class="pagination-link" aria-label="Goto page 1" @click="goToPage(1)" >1</a>
        </li>
        <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
        <li v-if="paginatedLinks.prev > 0">
          <a class="pagination-link" :aria-label="`Goto page ${paginatedLinks.prev}`" @click="goToPage(paginatedLinks.prev)" >{{paginatedLinks.prev}}</a>
        </li>
        <li>
          <a class="pagination-link is-current" :aria-label="`Page ${paginatedLinks.current}`" aria-current="page">{{paginatedLinks.current}}</a>
        </li>
        <li v-if="paginatedLinks.next < lastPage" >
          <a class="pagination-link" :aria-label="`Goto page ${paginatedLinks.next}`" @click="goToPage(paginatedLinks.next)" >{{paginatedLinks.next}}</a>
        </li>

        <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
        <li v-if="paginatedLinks.current < lastPage" >
          <a class="pagination-link" :aria-label="`Goto page ${lastPage}`" @click="goToPage(lastPage)" >{{lastPage}}</a>
        </li>
      </ul>
    </nav>
    
  </div>
  
</template>
<script>
  import store from '@/store';
  import IrishFerriesTimetable from '@/store/irish-feries-timetable.json';
  import DatePicker from '@/components/forms/DatePicker';

  export default {
    props: {
      uid: String,
      background: String,
      Title: String,
      itemsPerPage: Number,
      errorMessage: String
    },
    emits: ["clearLoading", "selectSailing"],
    components: {
      DatePicker         
    },
    store: store,
    data() {
      return {
        base_url: '',
        errors: [],

        days: ['sun','mon','tue','wed','thu','fri','sat'],
      
        thisPage: 1,
        lastPage: 0,
        paginatedLinks: {
         "prev" : 0, "current": 1, "next": 2
        },
        perPageItems: 1,
        indexSetFrom: 0,
        indexSetTo: 0,

        IrishFerriesTimetable,
        availableDates: [],

        //// Dates
        filteredDates: [],
        filteredDatesDisplayed: [],
        IrishFerriesTimetableDirection: [],

        DateOfTravel: '',
        RouteOfTravel: '',
        RouteDirection: '',
        
      }
    },
    mounted: function() {
      console.log('IrishFerries timepickerview timetable', this, store.state.user, this.IrishFerriesTimetable);
      
      this.perPageItems = (this.itemsPerPage !== undefined) ? this.itemsPerPage : this.perPageItems;

      //console.log('$refs', process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY , this.picOptions);
      this.applyDates();
      
        
      console.log('this.lastPage', this.filteredDates.length, this.perPageItems, this.lastPage);
      this.$emit('clearLoading');
      
      /*const {...arr} = this.Vehicles;
      console.log('arr', arr, this.Vehicles);
      this.vehiclesFiltered = arr;*/

    },
    async created() {
    },
    computed: {
        
    },
    watch: {

    },
    methods: {

      getRequiresFpIcon(fp){
        if(fp) {
          return '<i class="fa-solid fa-person-walking-luggage"></i>';
        }
      },
      
      getRequiresAccIcon(acc){
        if(acc) {
          return '<i class="fa-solid fa-bed"></i>';
        }
      },
      
      goToPage(pageNr){        
        this.thisPage = pageNr;  
        this.getBoundPages();        
        
      },

      
      paginate(navType = 'next'){
        if(navType === 'next') {
          this.thisPage++;
        } else if(navType === 'prev') {
          this.thisPage--;
        }
        
        this.getBoundPages();
        
      },
      
      getBoundPages() {
        
        this.indexSetFrom = (this.thisPage > 1) ? Math.floor(this.perPageItems * (this.thisPage-1)) : this.thisPage-1;
        this.indexSetTo = (this.thisPage > 1) ? Math.floor(this.perPageItems * this.thisPage) : this.perPageItems;
        console.log('indexSet', this.indexSetFrom, this.indexSetTo);
        ////this.filteredDates = this.availableDates.slice(this.indexSetFrom, this.indexSetTo);        
        
        const[...arr] = this.filteredDates.slice(this.indexSetFrom, this.indexSetTo);
        this.filteredDatesDisplayed = arr;
        ///console.log('getBoundPages', this.filteredDatesDisplayed, this.filteredDates, this.availableDates);
        console.log('getBoundPages filteredDatesDisplayed', this.filteredDatesDisplayed);
        this.lastPage = Math.floor(this.filteredDates.length/this.perPageItems);

        this.paginatedLinks = {
          "prev": (this.thisPage > 10) ? parseInt(this.thisPage-9) : 0,
          "current": parseInt(this.thisPage),
          "next": (this.thisPage < this.lastPage-9) ? parseInt(this.thisPage+9) : this.lastPage
        };              
          
      },

      
      filterAll(){
        
          this.filteredDates = this.availableDates.map((v) => {
            console.log(this.DateOfTravel, Object.keys(v)[0], (this.DateOfTravel === Object.keys(v)[0]));
            if((this.DateOfTravel !== '' && this.RouteOfTravel !== '' && this.RouteDirection !== '') && this.DateOfTravel === Object.keys(v)[0]){
              console.log('this.DateOfTravel, RouteOfTravel, & RouteDirection', this.DateOfTravel, this.RouteOfTravel, v[Object.keys(v)[0]][this.RouteOfTravel]);
              return {
                [this.DateOfTravel] : {
                  [this.RouteOfTravel]: {
                    [this.RouteDirection] : v[Object.keys(v)[0]][this.RouteOfTravel][this.RouteDirection]
                  }
                }
              };
              
            } else if((this.DateOfTravel !== '' && this.RouteOfTravel !== '') && this.DateOfTravel === Object.keys(v)[0]){
              console.log('this.DateOfTravel, RouteOfTravel, no RouteDirection', this.DateOfTravel, this.RouteOfTravel, v[Object.keys(v)[0]][this.RouteOfTravel]);
              return {
                [this.DateOfTravel] : {
                  [this.RouteOfTravel]: v[Object.keys(v)[0]][this.RouteOfTravel]
                }
              };
              
            } else if(this.DateOfTravel === '' && this.RouteOfTravel !== '' && this.RouteDirection !== '') {
              console.log('this no DateOfTravel, RouteOfTravel, RouteDirection', this.DateOfTravel, this.RouteOfTravel, v[Object.keys(v)[0]][this.RouteOfTravel]);
              return {
                [[Object.keys(v)[0]]] : {
                  [this.RouteOfTravel]: v[Object.keys(v)[0]][this.RouteOfTravel][this.RouteDirection]
                }
              };

            } else if(this.DateOfTravel === '' && this.RouteOfTravel !== ''  && this.RouteDirection === '') {
              console.log('this no DateOfTravel, RouteOfTravel Only', this.DateOfTravel, this.RouteOfTravel, v[Object.keys(v)[0]][this.RouteOfTravel]);
              return {
                [[Object.keys(v)[0]]] : {
                  [this.RouteOfTravel]: v[Object.keys(v)[0]][this.RouteOfTravel]
                }
              };

            } else if(this.DateOfTravel === '' && this.RouteOfTravel === ''  && this.RouteDirection !== '') {
              console.log('this no DateOfTravel, no RouteOfTravel, only outboundirection', this.DateOfTravel, this.RouteDirection, v[Object.keys(v)[0]][this.RouteOfTravel]);
              

            }

          });

          //// remove empty arrays parts
          this.filteredDates = this.filteredDates.filter((v) => v !== undefined);
          this.lastPage = Math.floor(this.filteredDates.length/this.perPageItems);
        

      },

      
      filterDateTravel(dateFiltered){
        this.isLoading = true;
        console.log('dateFiltered', dateFiltered);
        this.applyDates();
        if(dateFiltered !== null) {
          
          this.DateOfTravel = dateFiltered.toLocaleDateString("en-UK");
          
          this.filteredDates = this.filteredDates.filter((v) => {
            
            if(Object.keys(v)[0] === this.DateOfTravel) {
              return v;
            }

          });

          console.log('this.filteredDateOfTravel from filterDateTravel', this.filteredDates);
        
          this.lastPage = Math.floor(this.filteredDates.length/this.perPageItems);          
          
        }

        //// ---
        ////this.filterAll(type);
        this.getBoundPages();
        this.$emit('clearLoading');

      },
      
      
      filterCountry(){
        console.log('filterCountry Called...', this.uid, this.country, 'this.DateOfTravel', this.DateOfTravel);

        this.isLoading = true;

        this.IrishFerriesTimetableDirection = this.IrishFerriesTimetable[this.RouteOfTravel];
        console.log('this.IrishFerriesTimetable from filterCountry', this.filteredDates);

  
        this.filterAll();
        this.getBoundPages();
        this.$emit('clearLoading');
        
      },

      
      filterDirection(){

        this.isLoading = true;
          
        ///this.IrishFerriesTimetableDirection = this.IrishFerriesTimetable[this.RouteOfTravel][this.RouteDirection];
          
        this.filterAll();
        this.getBoundPages();
        this.$emit('clearLoading');        
        
      },

      //// Keep here in form and pass to timepickers as already
      getDaysArray(s,e) {for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;},
      
      applyDates(){
        
        ///console.log(this.IrishFerriesTimetable);

        const availableDates = this.getDaysArray(new Date(),new Date("2023-01-05"));
        ///this.availableDates = this.availableDates.map((v)=>v.toLocaleDateString("en-UK"));

        this.availableDates = availableDates.map((v)=>{
         
          let thisDate = v.toLocaleDateString("en-UK");
          
          let day = this.days[ v.getDay() ];
          let rtnArray = {
            [thisDate]: {}
          };

          ////console.log(thisDate, day, v);
          /// populate other journeys for every day
          for(let el in this.IrishFerriesTimetable) {

            rtnArray[thisDate][el] = {};

            ///console.log(el, this.IrishFerriesTimetable[el]);
            for(let ell in this.IrishFerriesTimetable[el]) {

              rtnArray[thisDate][el][ell] = {};

              ///console.log(ell, this.IrishFerriesTimetable[el][ell]);
              for(let elll in this.IrishFerriesTimetable[el][ell]) {

                rtnArray[thisDate][el][ell][elll] = {};
                
                if( ( Array.isArray(this.IrishFerriesTimetable[el][ell][elll].dates) && this.IrishFerriesTimetable[el][ell][elll].dates.includes(thisDate) ) ){
                  
                  rtnArray[thisDate][el][ell][elll]= {
                    'day' : day,
                    "dates" : this.IrishFerriesTimetable[el][ell][elll].dates,
                    "options" : this.IrishFerriesTimetable[el][ell][elll].options,
                    "cabins" : this.IrishFerriesTimetable[el][ell][elll].cabins,
                    "requiresAccommodation" : this.IrishFerriesTimetable[el][ell][elll].requiresAccommodation
                  };

                } else if(this.IrishFerriesTimetable[el][ell][elll].dates === 'any'){
                  
                  rtnArray[thisDate][el][ell][elll]= {
                    'day' : day,
                    "dates" : this.IrishFerriesTimetable[el][ell][elll].dates,
                    "options" : this.IrishFerriesTimetable[el][ell][elll].options,
                    "cabins" : this.IrishFerriesTimetable[el][ell][elll].cabins,
                    "requiresAccommodation" : this.IrishFerriesTimetable[el][ell][elll].requiresAccommodation
                  };

                } else if(this.IrishFerriesTimetable[el][ell][elll].dates.includes(day)){
                  
                  rtnArray[thisDate][el][ell][elll]= {
                    'day' : day,
                    "dates" : this.IrishFerriesTimetable[el][ell][elll].dates,
                    "options" : this.IrishFerriesTimetable[el][ell][elll].options,
                    "cabins" : this.IrishFerriesTimetable[el][ell][elll].cabins,
                    "requiresAccommodation" : this.IrishFerriesTimetable[el][ell][elll].requiresAccommodation
                  };

                } else {
                  
                  /// remove empty items
                  delete rtnArray[thisDate][el][ell][elll];

                }

              }
              
              ///console.log('check len', rtnArray[thisDate][el][ell], Object.keys(rtnArray[thisDate][el][ell]).length);
              /// remove empty items
              if(Object.keys(rtnArray[thisDate][el][ell]).length <= 0) {
                ///console.log('check len...',rtnArray[thisDate][el][ell]);
                delete rtnArray[thisDate][el][ell];                
              }


            }

            ////console.log('check len2', rtnArray[thisDate][el], Object.keys(rtnArray[thisDate][el]).length);
            if(Object.keys(rtnArray[thisDate][el]).length <= 0) {
              ///console.log('check len2...',rtnArray[thisDate][el]);
              delete rtnArray[thisDate][el];              
            }

          }

          return rtnArray;
        
        });

        this.filteredDates = this.availableDates;
        console.log('dates...', this.availableDates, this.filteredDates, this.filteredDatesDisplayed);
        this.getBoundPages();
        
      },

      /// Emitting
      selectSailing(e){

        console.log('selectSailing', e);
        
        let fp = e.target.dataset.fp;
        let sailingDate = e.target.dataset.sailingdate;
        let route = e.target.dataset.route;  
        let departs = e.target.dataset.departs;
        let from = e.target.dataset.from;
        let ferry = e.target.dataset.ferry;  
        let selectedSailing = this.filteredDates.find((data) => {
          return Object.keys(data)[0] === sailingDate;
        });
        let cabins = selectedSailing[sailingDate][route][from][ferry]['cabins'];
        let requiresAccommodation = e.target.dataset.requiresaccommodation;

        console.log('departs clicked', this.uid, e.target.dataset, departs, cabins, requiresAccommodation);

        this.$emit('selectSailing', this.uid, fp, sailingDate, route, departs, from, ferry, cabins, requiresAccommodation);
      },      
      /* goToPage(pageNr){
           
      },
      paginate(navType = 'next'){
        this.$emit('paginate', this.uid, navType);
      },
      filterDateTravel(dateSent){
        console.log('filterDateTravel triggered', dateSent, this.uid);
        this.$emit('filterDateTravel', this.uid, dateSent);
      },
      filterCountry(){
        this.$emit('filterCountry', this.uid, this.country);
      },
      filterDirection(){
        this.$emit('filterDirection', this.uid, this.direction);
      } */
    },
    

    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
.card {
  max-width: none;
}
.content table td, .content table th {
  vertical-align: middle;
}
.select {
  width: 100%;
}
@media screen and (mx-width: 768px) {
  .columns {
    display: flex;
    flex-direction: column-reverse;
  }
  .dp__input {
    padding: 3px 12px;
    border-radius: none;
  }
}
</style>