import * as types from './mutation-types'

export const mutations = {
  [types.SIGN_IN] (state, user_payload) {
    state.user = user_payload
    state.isLoggedIn = true;
  },

  [types.SIGN_OUT] (state) {
    state.user = {}
    state.isLoggedIn = false;
  },

  [types.SET_EVENTS] (state, events_payload) {
    state.events = events_payload
  },

}
