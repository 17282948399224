<template>
  <div :id="`cabin-${uid}`" :class="`box ${background} ${uid}`" >
    <div class="columns mb-4">
      <div class="column">
        <h4 class="title has-text-white">{{Title}} <i class="is-pulled-right fa-solid fa-bed mr-2"></i></h4>
        <p v-if="errorMessage !== ''" :id="`cabin-${uid}-error`" class="has-background-warning p-3 has-text-dark is-size-4" >** {{errorMessage}} **</p>
      </div>
                      
    </div>
    <!-- cabin List -->
    <div class="columns" >      
      <div class="column" >
        <p><label :for="`${uid}-cabin`" >{{uid}} Cabin</label></p>
        <select :id="`${uid}-cabin`" class="select is-wide" v-model="cabin" >
          <option :selected="requiresAccommodation === undefined || (requiresAccommodation !== undefined && requiresAccommodation !== 'Y') ? 'selected' : ''" v-if="requiresAccommodation === undefined || (requiresAccommodation !== undefined && requiresAccommodation !== 'Y')" value="No Cabin">No Cabin</option>
          <option :selected="(requiresAccommodation !== undefined && requiresAccommodation !== 'N') && index === 0 ? 'selected' : ''" v-for="(value, index) in Cabins" :key="index" :value="`${value}`" >{{value}}</option>
        </select>
      </div>
      
    </div>    
    <!-- .cabin List -->   
    
    
  </div>
  
</template>
<script>
  import store from '@/store';

  export default {
    props: {
      uid: String,
      background: String,
      Title: String,
      Cabins: Array,
      requiresAccommodation: String,
      errorMessage: String
    },
    emits: ["updateSelectedCabin"],
    components: {       
    },
    store: store,
    data() {
      return {
        base_url: '',
        errors: [],
        cabin: '',
        
      }
    },
    mounted: function() {
      console.log('IrishFerries vehicles...', this, store.state.user, this.IrishFerriesTimetable);
    },
    async created() {
    },
    computed: {
        
    },
    watch: {
      // whenever question changes, this function will run
      cabin(newVal){
        console.log('newVal', newVal);
        this.updateSelectedCabin();
      },
    },
    methods: {           
     
      updateSelectedCabin(){
        console.log('calling updateSelectedCabin', this.uid, this.cabin);
        this.$emit('updateSelectedCabin', this.uid, this.cabin);
      }
      
      
    },

    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
.card {
  max-width: none;
}
.content table td, .content table th {
  vertical-align: middle;
}
.select {
  width: 100%;
}
@media screen and (mx-width: 768px) {
  .columns {
    display: flex;
    flex-direction: column-reverse;
  }
  .dp__input {
    padding: 3px 12px;
    border-radius: none;
  }
}
</style>