<template>
  <img v-if="!isLoggedIn" alt="Ireland Flag" src="assets/logo.png">  
  <ClubMenu v-if="isLoggedIn" @getStaticPage="getStaticPage" @getPage="getPage" @getMembersPage="getMembersPage" @getForm="getForm" @logOut="logOut" />
  <InstallPrompt />
  <MembersCard :userId="userId" v-if="pageId === 0 && membersPageId === 0 && staticPageName === '' && isLoggedIn && !showRegistrationForm && !showForgotPasswordForm && !loadForm" />
  <!--<router-view></router-view>-->
  <ClubLogin v-else-if="!isLoggedIn && !showRegistrationForm && !showForgotPasswordForm" @logIn="logIn" @forgotPassword="forgotPassword" @register="register" />
  <ForgotPassword v-else-if="!isLoggedIn && !showRegistrationForm && showForgotPasswordForm" @back="back" />
  <ClubRegister v-else-if="!isLoggedIn && showRegistrationForm && !showForgotPasswordForm" @back="back" />
  <ClubPage v-if="isLoggedIn && (pageId>0||membersPageId>0) && staticPageName === '' && !loadForm" :pageId="pageId" :membersPageId="membersPageId" />
  <ClubStaticPage v-if="isLoggedIn && pageId === 0 && membersPageId === 0 && staticPageName !== '' && !loadForm" :isLoggedIn="isLoggedIn" :userId="userId" :staticPageName="staticPageName" :pageId="pageId" />
  
  <ClubForms v-if="isLoggedIn && loadForm" :form="form" :userId="userId" :staticPageName="staticPageName" :pageId="pageId" />
  <p class="has-text-right is-size-7 pr-4 pt-0" >Version: {{version}}</p>
</template>

<script>
///import router from './routes'
import store from './store'
import config from './config'
import InstallPrompt from './components/InstallPrompt.vue'
import ClubMenu from './components/ClubMenu.vue'
import ClubLogin from './components/ClubLogin.vue'
import MembersCard from './components/MembersCard.vue'
import ForgotPassword from './components/ForgotPassword.vue'
import ClubRegister from './components/ClubRegister.vue'
import ClubStaticPage from './components/ClubStaticPage.vue'
import ClubPage from './components/ClubPage.vue'
import ClubForms from './components/ClubForms.vue'


export default {
  name: 'App',
  components: {
    InstallPrompt,
    ClubMenu,
    ClubLogin,
    MembersCard,
    ForgotPassword,
    ClubRegister,
    ClubStaticPage,
    ClubPage,
    ClubForms
  },  
  data() {
    return {
      pageId: 0,
      membersPageId: 0,
      staticPageName: '',
      userId: 0,
      user: {},
      isLoggedIn: false,
      showRegistrationForm: false,
      showForgotPasswordForm: false,
      loadForm: false,
      form: '',
      version: config.version
    }
  },
  computed: {
    /*username() {
      // We will see what `params` is shortly
      return this.$route.params.username
    }*/
  },
  mounted: function() {
    console.log('App View',this.isLoggedIn)
    let user = sessionStorage.getItem(
        "user_data_"
    );
    
    if(user) {
      user = JSON.parse(user);
      console.log(user, user.user_id);
      store.state.user = this.user = user;
      this.userId = this.user.user_id;      
      this.isLoggedIn = store.state.isLoggedIn = true;
    }

  },
  methods: {
    
    logIn(user){
      console.log('called logIn');
      store.commit('SIGN_IN', user);
      this.userId = user.user_id;
      this.user = user;
      this.isLoggedIn = true;
      ///router.push({ name: 'userId', params: this.userId },{name: 'user', params: this.user },{name: 'isLoggedIn', params: this.isLoggedIn })
    },
    logOut(){
      store.commit('SIGN_OUT', {})
      this.userId = 0;
      this.user = {};
      this.isLoggedIn = false;
      sessionStorage.clear();
    },
    forgotPassword(){
      this.showForgotPasswordForm = true;
    },
    register(){
      this.showRegistrationForm = true;
    },
    back(){
      this.showRegistrationForm = false;
      this.showForgotPasswordForm = false;
      this.loadForm = false;
      this.form = '';
    },
    getStaticPage(staticPageName){
      console.log('get static page called...');
      this.membersPageId = 0;
      this.pageId = 0;
      this.staticPageName = staticPageName;
      console.log(this.pageId, this.staticPageName);
      this.loadForm = false;
      this.form = '';
    },
    getPage(id){
      this.staticPageName = '';
      this.membersPageId = 0;
      this.pageId = id;
      console.log('getPage', this.pageId);
      this.loadForm = false;
      this.form = '';
    },
    getMembersPage(id){
      this.staticPageName = '';
      this.pageId = 0;
      this.membersPageId = id;
      console.log('membersPageId', this.membersPageId);
      this.loadForm = false;
      this.form = '';
    },
    getForm(form){
     this.staticPageName = '';
     this.pageId = 0;
     this.loadForm = true;
     this.form = form;
     console.log('this.form is now', this.form);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}

</style>
