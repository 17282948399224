export default {  
  "sb_site_url": "https://portsmouthirishsociety.egbx.uk",
  "lv_site_url": "https://portsmouthirishclub.org",
  "rest_endpoint": "wp-json/Portsmouth_Irish_Society/v2",
  "wp_rest_endpoint": "wp-json/wp/v2",
  "site_key": "np4fqbhv9OQOWc4OTAau",
  "setup_id": 1,
  "setup_action": "membership_setup",
  "skin":"skin3",
  "default_subscription_type": "paid_finite",
  "arm_selected_payment_mode": "auto_debit_subscription",
  "endpoints": {
    "forgot_password": "password-reminder",
    "login": "member-login",
    "register": "",
    "pages" : "pages",
    "members_pages" : "members-page",
    "memberships": "memberships",
    "update_member": "member-update",
    "renew_membership": "membership-renew",
    "create_entry": "membership-create-entry"
  },
  "entryId": 0,
  /*"paypal": {
    "lv_paypal_url": "https://www.paypal.com/cgi-bin/webscr",    
    "sb_paypal_url": "https://www.sandbox.paypal.com/cgi-bin/webscr",
    "lv_business": "charliedmbrown@yahoo.com",
    "sb_business": "sb-647wb38291410@business.example.com",
    "intent": "authorize",
    "commit": true,
    "notify_url": "?arm-listener=arm_paypal_api",
    "cancel_return": "cancel_payment/",
    "return": "thank_you/",
    "cmd": "_xclick",    
    "rm": 2,
    "lc": "en_GB",
    "no_shipping": 1,
    "currency_code": "GBP",
    "page_style": "primary",
    "charset": "UTF-8",
    "credentials": {
      "sandbox": "AV1jtl378MHNJ6cu0ga-Jk4obtY-V8GTaq-1HSodRpkZfctU4MBzjrih8lNYtrwUepbCZVONhWNTjou0",
      "production": "AeUmR3zj2SrTV59v4MUTPj3XW57XvfyNPIz9yPV7utzzStUJp_TYjbkGEWKP-Mmo7UM7O0Z9k06hiQ2t"
    }
  },
  "stripe": {
    "sb_credentials": {
      "pKey": "pk_test_51JuzXDIe3YFPqmlz51qvUXVuL8v8sUHTtyN9FloaAYeR3BUksaurr4kRFWVNkIBtv7RkJuEVwDL1m3r2JmQqhmmJ00PXdvpoDd"
      
    },
    "lv_credentials": {
      "pKey": "pk_live_51JuzXDIe3YFPqmlzSCSGFl8h8QUbY9OeZRwKwD8vw3xPL89cSoE5US9QKGwjyB2CZf2m3Kw5e7kf78ePBHqePKYw00j2VfXeK6"
    }
  },*/
  "version": "3.4.1",
  "version_log": [
    {
      "3.4.1" : {
        "date":"2024-09-08", 
        "description": `Added in loading block on main login,
        Removed the descriptive error message as this gives out what credential failed during API call,
        Fixed page load bug when navigating from constitution to the what's on page`,
      },
    },
    {
      "<3.4.0" : {
        "date":"<2024-09-08", 
        "description": `App had several versions but had not lagged the version history and what was updated before.`,
      },
    },
  ],
  "env": "lv"
}
