<template>
  <ClubOffers v-if="staticPage==='Offers'" @back="back" :pageId="pageId" :userId="userId" :isLoggedIn="isLoggedIn" :staticPageName="staticPageName" />
  <RTENews v-if="staticPage==='RTENews'" @back="back" :pageId="pageId" :userId="userId" :isLoggedIn="isLoggedIn" :staticPageName="staticPageName" />
  <ThingsToDo v-if="staticPage==='ThingsToDo'" @back="back" :pageId="pageId" :userId="userId" :isLoggedIn="isLoggedIn" :staticPageName="staticPageName" />
</template>
<script>
  import store from '@/store';
  import he from 'he'; 
  import ClubOffers from './staticPages/ClubOffers.vue';
  import RTENews from './staticPages/RTENews.vue';
  import ThingsToDo from './staticPages/ThingsToDo.vue'
  
  export default {
    props: {
      pageId: Number,
      staticPageName: String,
      userId: Number,
      isLoggedIn: Boolean,
      form: String
    },
    emits: ["back"],
    components: {
      ClubOffers,
      RTENews,
      ThingsToDo
    },
    store: store,
    watch: {
      staticPageName(newVal){
        console.log('staticPageName changed', newVal);
        this.staticPage = newVal;
      }
    },
    data() {
      return {
        user_id: 0,
        user: {},
        errors: [],
        staticPage: '',
      }
    },
    mounted: function() {
      
      this.staticPage = this.staticPageName;
      this.user_id = this.userId;
      console.log('static page mounted... this.staticPageName', this.staticPageName, this.staticPage, this.user_id);
    },
    async created() {
      
    },
    computed: {
      
    },	
    methods: {
      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      back(){
        this.$emit('back');
      }
    },

    beforeUnmount: function() {
      
    }    
  }
</script>