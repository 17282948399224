import { createStore } from 'vuex'
import { mutations } from './mutations'
import * as actions from './actions'



// Create a new store instance.
const store = createStore({
  state () {
    return {
      isLoggedIn: false,
      user: {}
    }
  },
  mutations,
  actions
})

export default store;