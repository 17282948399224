<template>
  <header class="heading">
    <h1 class="is-size-3">{{pageTitle}}</h1>
  </header>
  <div class="container is-max-desktop" v-if="pageContent === null" >
    <p>Special Offers are available to all our members. This page is updated regularly. T&amp;C's Apply</p>
  </div>
  <div class="container is-max-desktop" v-html="pageContent"></div>
  <ClubForms v-if="isLoggedIn && loadForm" :form="form" :userId="userId" :staticPageName="staticPageName" :offerId="offerId" :pageId="pageId" />
  <div class="container is-max-desktop" v-if="pageContent === null" >
    <div class="list">
      <div class="list-item" v-for="offer in offers" :key="offer.id">
        <div class="list-item-image">
          <figure class="image is-150x150">
            <img :src="offer.content.logo_thumb" height="150" width="150">
          </figure>
        </div>

        <div class="list-item-content">
          <div class="list-item-title">{{offer.title.rendered}}</div>
          <div class="list-item-description" v-html="offer.excerpt.rendered" ></div>
        </div>

        <div class="list-item-controls">
          <div class="buttons is-right">
            <a class="button is-primary" @click="displayOffer(offer.id)" >
              <span>View Offer</span>
            </a>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>
<script>
  import store from '@/store';
  import config from '@/config';
  import axios from 'axios';
  import he from 'he';  
  import ClubForms from '@/components/ClubForms.vue'
  
  export default {
    props: {
      userId: Number,
      isLoggedIn: Boolean,
      pageId: Number,
      staticPageName: String
    },
    emits: ["getForm"],
    components: {
      ClubForms
    },
    store: store,
    data() {
      return {
        pageTitle: '',
        pageContent: '',
        loadForm: false,
        form: '',
        offers: [],
        errors: [],
        tryLoginAgain: false,
        offerId: 0
      }
    },
    mounted: function() {
      this.getOffers();
    },
    async created() {
      
    },
    computed: {
      
    },	
    methods: {
      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      getOffers(offer_id = undefined){
        console.log('called Offers', this.userId, this.pageId, offer_id, (offer_id === undefined), this.staticPageName);
        
        if(offer_id !== undefined) {
          this.offerId = offer_id;
        }

        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          
          const data = {id: (offer_id === undefined) ? this.pageId : offer_id};

          console.log('data', data);

          const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
          const url = base_url + '/' + config.wp_rest_endpoint + '/offers' + ((offer_id === undefined) ? '' : '/' + offer_id);
          console.log(url);
          axios.get(url,
            data,
            {
              headers: headers
            }
          ).then((response) => {

              console.log('response', response);
              
              if(response.data.error) {
                this.errors.push(response.data.error);
                
              }
              
              if(response.data.error === undefined){                
                console.log('pageContent', response);
                if(offer_id === undefined) {
                  this.offers = response.data;
                  this.pageTitle = 'Special Offers';
                  //this.pageContent = response.data.content.rendered.replace('wp-block-columns has-2-columns', 'columns p-3 mb-1').replaceAll('wp-block-column', 'column');
                  this.pageContent = null;

                } else {

                  this.offers = response.data;
                  this.pageTitle = response.data.title.rendered;
                  this.pageContent = response.data.content.rendered.replace('wp-block-columns has-2-columns', 'columns p-3 mb-1').replaceAll('wp-block-column', 'column');
                  this.form = response.data.vue_component_form;
                  if(this.form) {
                    this.loadForm = true;
                  } else {
                    this.loadForm = false;
                  }

                }

                console.log('pageContentRendered', this);

              }
              
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        } 

      },
      displayOffer(offer_id){
        this.getOffers(offer_id);
      },
    },
    beforeUnmount: function() {
      
    }    
  }
</script>