<template>
  <div id="vehicle-select" :class="`box ${background} ${uid}`" >
    <div class="columns mb-4">
      <div class="column">
        <h4 class="title">{{Title}} <i class="is-pulled-right fa-solid fa-car mr-2"></i></h4>
        <p v-if="errorMessage !== ''" :id="`vehicle-${uid}-error`" class="has-background-danger p-3 has-text-white is-size-4" >** {{errorMessage}} **</p>
      </div>
                      
    </div>
    <!-- Vehicle List -->
    <div class="columns" >      
      <div class="column" >
        <p><label :for="`${uid}-vehicleType`" >Mode of Travel</label></p>
        <select :id="`${uid}-vehicleType`" class="select is-wide" v-model="vehicleType" @change="filterSizeLength()" >
          <option value="">...Select</option>
          <option v-for="(value, index) in vehiclesFiltered" :key="index" :value="`${index}`" >{{value.type}}</option>
        </select>
      </div>
      <div class="column" v-if="this.VehicleSizeLengthsArr" >
        <p><label :for="`${uid}-vehicleSizeLength`" >Vehicle Size Length...</label></p>
        <select :id="`${uid}-vehicleSizeLength`" class="select is-wide" v-model="vehicleSizeLength" >
          <option value="">...Select</option>
          <option v-for="(value, index) in VehicleSizeLengthsArr" :key="index" :value="index" >{{value}}</option>
        </select>
      </div>
      <div class="column" v-else>
      </div>
    </div>
    <div v-if="vehicleType === 'F'" class="columns" >
      <div class="column" >
        <p><label for="termsF" >{{Vehicles.vehicles[vehicleType]['terms']}}</label> <input id="termsF" type="checkbox" v-model="termsF" /></p>
      </div>
    </div>
    <div v-if="vehicleType === 'V'" class="columns" >
      <div class="column" >
        <p>{{Vehicles.vehicles[vehicleType]['terms']}}</p>
      </div>
    </div>    
    <!-- .Vehicle List -->   
    <div class="columns" >
      <div class="column" v-if="this.vehicleType !== '' && this.vehicleType !== 'F' && this.vehicleType !== 'MCYV' && this.vehicleType !== 'ASCV'">
        <label for="hasCaravanTrailer" class="is-clickable" >With Caravan or Trailer</label> <input class="is-clickable" id="hasCaravanTrailer" type="checkbox" v-model="hasCaravanTrailer" >
      </div>
      <div class="column" v-if="this.hasCaravanTrailer" >
        <p><label :for="`${uid}-trailerSizeLength`" >Trailer Size Length...</label></p>
        <select :id="`${uid}-trailerSizeLength`" class="select is-wide" v-model="trailerSizeLength" >
          <option value="">...Select</option>
          <option v-for="(value, index) in Vehicles.trailers" :key="index" :value="index" >{{value}}</option>
        </select>
      </div>
      <div class="column" v-else>
      </div>
    </div>

    <div v-if="vehicleType !== '' && vehicleType !== 'F'" class="columns" >
      <div class="column" >
        <p><label for="vehicleReg" >Vehicle Registration</label> <input id="vehicleReg" type="input" v-model="vehicleReg" /></p>
      </div>
    </div>
    
    <div v-if="vehicleType !== '' && vehicleType !== 'F'" class="columns" >
      <div class="column" >
        <p><label for="evCharging" >Extras: Add Ev Charging if available?</label> <input id="evCharging" type="checkbox" v-model="ExtrasEvCharging" /></p>
      </div>
    </div>
    
    
  </div>
  
</template>
<script>
  import store from '@/store';
  import Vehicles from '@/store/vehicles.json';

  export default {
    props: {
      uid: String,
      background: String,
      Title: String,
      outBoundFpSelected: String,
      returnFpSelected: String,
      errorMessage: String
    },
    emits: ["updateVehicleType", "updateVehicleReg", "updateTermsF", "updateVehicleSizeLength", "updateHasCaravanTrailer", "updateCaravanTrailerSelection", "updateExtras"],
    components: {       
    },
    store: store,
    data() {
      return {
        base_url: '',
        errors: [],
        Vehicles,
        vehiclesFiltered: {},
        vehicleType: '',
        vehicleReg: '',
        VehicleSizeLengthsArr: undefined,
        vehicleSizeLength: '',
        trailerSizeLength: '',        
        hasCaravanTrailer: false,
        ExtrasEvCharging: false,
        termsF: false,
        
      }
    },
    mounted: function() {
      
      if(this.outBoundFpSelected === 'N' || this.returnFpSelected === 'N') {
          
        const {...arr} = this.Vehicles.vehicles;
        delete arr['F'];         
        this.vehiclesFiltered = arr; 
        console.log('arr', arr, this.Vehicles, this.vehiclesFiltered);         

      } else {
        
        this.vehiclesFiltered = this.Vehicles.vehicles;
        console.log('is Y', this.vehiclesFiltered);

      }

    },
    async created() {
    },
    computed: {
        
    },
    watch: {
      outBoundFpSelected(newVal){        
        this.fitlerVehicleList(newVal);
      },
      returnFpSelected(newVal){
        this.fitlerVehicleList(newVal);
      },
      // whenever question changes, this function will run
      vehicleType(newVal){
        console.log('newVal', newVal);
        this.updateVehicleType();
      },
      vehicleReg(newVal){
        console.log('vehicleReg', newVal);
        this.updateVehicleReg();
      },
      termsF(newVal){
        console.log('newVal', newVal);
        this.updateTermsF();
      },
      vehicleSizeLength(newVal){
        console.log('newVal', newVal);
        this.updateVehicleSizeLength();
      },
      hasCaravanTrailer(newVal) {        
        console.log('newVal', newVal);
        this.updateHasCaravanTrailer();
      },
      trailerSizeLength(newVal){
        console.log('newValSelection', newVal);
        this.updateCaravanTrailerSelection();
      },
      ExtrasEvCharging(newVal){
        console.log('updateExtras', newVal);
        this.updateExtras();
      }
    },
    methods: {    
      
      fitlerVehicleList(newVal){
        
        if(newVal === 'N') {
            
          const {...arr} = this.Vehicles.vehicles;
          delete arr['F'];         
          this.vehiclesFiltered = arr; 
          console.log('arr', arr, this.Vehicles, this.vehiclesFiltered);         

        } else {
          
          this.vehiclesFiltered = this.Vehicles.vehicles;
          console.log('is Y', this.vehiclesFiltered);

        }

      },
      
      filterSizeLength(){
        if(this.vehicleType !== ''){
          this.VehicleSizeLengthsArr = this.Vehicles.vehicles[this.vehicleType]['heightLengthOptions'];
        } else {
          this.VehicleSizeLengthsArr = undefined;
        }

      },
      updateVehicleType(){
        this.vehicleSizeLength = '';
        this.$emit('updateVehicleSizeLength', '');
        this.termsF = false;
        this.$emit('updateTermsF', false);

        if(this.vehicleType !== '') {
          console.log('calling updateVehicleType', this.vehicleType, this.Vehicles.vehicles[this.vehicleType].type);
          this.$emit('updateVehicleType', this.vehicleType + '=' + this.Vehicles.vehicles[this.vehicleType].type);
        } else {
          this.$emit('updateVehicleType', '');          
        }
      },
      updateVehicleReg(){
        this.$emit('updateVehicleReg', this.vehicleReg);
      },
      updateTermsF(){
        console.log('calling updateTermsF', this.termsF);
        this.$emit('updateTermsF', this.termsF);
      },
      updateVehicleSizeLength(){
        this.termsF = false;
        this.$emit('updateTermsF', false);
        if(this.vehicleSizeLength !== '') {
          console.log('calling updateVehicleSizeLength', this.vehicleSizeLength, this.VehicleSizeLengthsArr[this.vehicleSizeLength]);
          this.$emit('updateVehicleSizeLength', this.vehicleSizeLength + '=' + this.VehicleSizeLengthsArr[this.vehicleSizeLength]);
        }
      },
      updateHasCaravanTrailer(){
        console.log('calling', this.hasCaravanTrailer);
        this.trailerSizeLength = '';
        this.$emit('updateCaravanTrailerSelection', '');
        this.$emit('updateHasCaravanTrailer', this.hasCaravanTrailer);
      },
      updateCaravanTrailerSelection(){
        console.log('calling updateCaravanTrailerSelection', this.trailerSizeLength, this.Vehicles.trailers[this.trailerSizeLength]);
        this.$emit('updateCaravanTrailerSelection', this.trailerSizeLength + '=' + this.Vehicles.trailers[this.trailerSizeLength]);
      },
      updateExtras(){
        console.log('calling updateExtras', this.ExtrasEvCharging);
        this.$emit('updateExtras', this.ExtrasEvCharging);
      }
      
      
    },

    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
.card {
  max-width: none;
}
.content table td, .content table th {
  vertical-align: middle;
}
.select {
  width: 100%;
}
@media screen and (mx-width: 768px) {
  .columns {
    display: flex;
    flex-direction: column-reverse;
  }
  .dp__input {
    padding: 3px 12px;
    border-radius: none;
  }
}
</style>