<template>
  <fieldset>
    <legend class="sub-title py-3" >Any Pets?</legend>
    <div class="box has-background-warning-dark has-text-white otherUsers" >
      <p class="is-pulled-right" ><i class="fa-solid fa-paw mr-2"></i></p>
      <p><label for="addOtherPtFn" >Name(s): </label><input id="addOtherPtFn" class="input otherPets" type="text" placeholder="Pet's Name, I.E. Montiduke III" required="required" ></p>
      <p><label for="addOtherPt" >Type of Pet: </label><input id="addOtherPt" class="input otherPets" type="text" placeholder="IE, dog/cat" required="required" ></p>
      <a v-if="!isEditingPet" class="button is-info" data-target="otherUsers" @click="addPet" >+ Add Pet</a>
      <a v-if="isEditingPet" class="button is-primary" data-target="otherUsers" @click="addPet" >Save</a>
    </div>
    <div class="other_Pets_list is-size-7" >
      <table class="table" >
        <thead>
          <tr>
            <th><abbr title="First Names">Name</abbr></th>
            <th>Type</th>
            <th>Edit Pet</th>
            <th>Remove Pet</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th><abbr title="First Names">Name</abbr></th>
            <th>Type</th>
            <th>Edit Pet</th>
            <th>Remove Pet</th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="(Pet, index) in PetsTravelling" :key="index" class="has-background-warning-dark has-text-white" >
            <td>{{Pet.fn}}</td>
            <td><span v-html="getPetIcon(Pet.pt)" ></span></td>
            <td><a class="button is-small is-info" title="remove Pet from this list" @click="editPet(index)" ><i class="fa-solid fa-pen-to-square"></i></a></td>
            <td><a class="button is-small is-danger" title="remove Pet from this list" @click="removePet(index)" ><i class="fa-solid fa-delete-left"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </fieldset>
</template>
<script>
  import store from '@/store';
  import he from 'he'; 

  export default {
    props: {
      userId: Number
    },
    emits: ["updatePetsList"],
    components: {          
    },
    store: store,
    data() {
      return {
        base_url: '',
        user_id: 0,
        user: {},
        fn: '',
        pt: '',
        user_original_details:{},
        loadingTimeOut: 3600,
        PetsTravelling: [          
        ],
        currentPet: -1,
        errors: [],
        oneWay: false,
        tryLoginAgain: false,
        isEditingPet: false,   

      }
    },
    mounted: function() {      

    },
    async created() {

    },
    computed: {
      form_random_key(){
        return this.setUpId + '_' + this.randomCode();
      },
      form_random_keyCheck(){
        return this.captchaCode();
      }
    },	
    methods: {
      
      captchaCode(length = 8) {
          const possible_letters = '23456789bcdfghjkmnpqrstvwxyz';
          /*const random_dots = 0;
          const random_lines = 20;*/
          let code = '';
          for (let i = 0; i < length; i++) {
              let rand = Math.round(Math.random(0, possible_letters.length - 1) * 10);
              code += possible_letters[rand];
          }
          return code;
      },
      randomCode: function(length = 10) {
          const charLength = Math.round(length * 0.8);
          const numLength = Math.round(length * 0.2);
          const keywords = [
              {
                "count": charLength,
                "char": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
              },
              {
                "count": numLength,
                "char": "0123456789"
              }
            ];
          
          const temp_array = keywords.map(function(char_set) {
              let n_array = [];
              for (let i = 0; i < char_set['count']; i++) {
                n_array.push(char_set['char'][Math.round(Math.random(0, char_set['char'].length - 1) * 10)]);
              }
              return n_array.join('');
          }); 
          return temp_array.join('');
      },      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },

      getPetIcon(petType){
        let icon = '';
        switch(true){
          case petType.toLowerCase() === 'dog':
            icon = '<i class="fa-solid fa-dog"></i>';
            break;
          case petType.toLowerCase() === 'cat':
            icon = '<i class="fa-solid fa-cat"></i>';
            break;
          default:
            icon = '<i class="fa-solid fa-paw"></i>';
        }

        return icon;

      },

      // TODO: Move to a Pets Component & emit
      clearOtherPetsFromForm(){
        const otherPets = document.querySelectorAll('.input.otherPets');
        [...otherPets].forEach(el => el.value = '');

      },

      // TODO: Move to Pets Componenet & emit
      addPet(){

        const fn = document.getElementById('addOtherPtFn');
        const pt = document.getElementById('addOtherPt');

        let isError = false;
        
        switch(true){
          case fn.value === '':
            fn.reportValidity();
            isError = true;
            break;
          case pt.value === '':
            pt.reportValidity();
            isError = true;
            break;          
        }

        if(isError) {
          return;
        }

        if(this.currentPet <= 0){
          this.PetsTravelling.push({
            fn: fn.value,
            pt: pt.value,
          });
        } else {
          this.PetsTravelling[this.currentPet] = {
            fn: fn.value,
            pt: pt.value
          };
          this.currentPet = -1;
          this.isEditingPet = false;
        }

        this.$emit('updatePetsList', this.PetsTravelling);

        /// Clear Form...
        this.clearOtherPetsFromForm();


      },
      // TODO: Move to Pets Componenet & emit
      removePet(index){
        let remP = confirm("Are you sure you want to remove this Pet from the list?");
        if(remP) {
          this.PetsTravelling = this.PetsTravelling.filter( (el, i) => index !== i);
        }
        this.$emit('updatePetsList', this.PetsTravelling);
      },
      // TODO: Move to Pets Componenet & emit
      editPet(index){
        const editing = this.PetsTravelling[index];
        this.currentPet = index;

        document.getElementById('addOtherPtFn').value = editing.fn;
        document.getElementById('addOtherPt').value = editing.pt;

        this.isEditingPet = true;

        this.$emit('updatePetsList', this.PetsTravelling);

      },     

    },

    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
.card {
  max-width: none;
}
.content table td, .content table th {
  vertical-align: middle;
}
.select,
.routes {
  width: 100%;
}
.is-strikethrough {
  text-decoration:line-through;
  color: #e0e0e0;
}
@media screen and (mx-width: 768px) {
  .columns {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>