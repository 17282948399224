<template>
  <header class="heading">
    <h1 class="is-size-3" >My Membership</h1>
  </header>
  <div class="container is-max-desktop" >
    <div class="columns" >
      <div class="column col-details" >
        <div class="card" >
          <header class="card-header">
            <p class="card-header-title" v-if="!membershipUpdated" >
              My Details:
            </p>
            <p class="card-header-title has-background-primary has-text-white" v-if="membershipUpdated" >
              Thank You, your details have been updated!
            </p>
            <!--<button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>-->
          </header>
          <div class="card-content">        
            <div class="content has-text-left">
              <form>
                <span v-if="detailsUpdated" class="has-text-primary is-size-7" >Details Updated!</span>
                <p><label for="fn" >My First Name: </label><input id="fn" class="input" type="text" placeholder="First Name" v-model="fn" required="required" ></p>
                <p><label for="ln" >My Last Name: </label><input id="ln" class="input" type="text" placeholder="Last Name" v-model="ln" required="required" ></p>
                <p><label for="email" >Email: </label><input id="email" class="input" type="text" placeholder="Email or Username" v-model="em" required="required" ></p>
                <p><label for="password" >Change Password: </label><input id="password" class="input" type="password" placeholder="********" v-model="pw" ></p>
                <p><label for="password-confirm" >Change Password (confirm): </label><input id="password" class="input" type="text" placeholder="********" v-model="pwConfirm" ></p>
                <p><label for="dob" >Date of Birth: </label><input id="dob" class="input" type="text" placeholder="ie... 01/10/1970" v-model="dob" ></p>
                <p class="mb-7"><button class="button is-success is-fullwidth" @click="updateMember" >Update</button></p>
                <p class="is-flex is-justify-content-space-around is-align-content-center" ><a class="is-warning" @click="back" >Back</a></p>            
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="column col-membership" >
        <div class="card" >
          <header class="card-header">
            <p class="card-header-title">
              Online ID: {{user.user_id}}
            </p>
          </header>
          <div class="card-content">        
            <div class="content has-text-left">
              {{currentMembership.arm_current_plan_detail.arm_subscription_plan_name}} : {{user.primary_status}}<br />
              From: {{currentMembership.start_date_formatted}}<br />
              Expires: {{currentMembership.expiry_date_formatted}}             
            </div>
          </div>
        </div>
      <!--</div>
      <div class="column" >-->
        <div class="card" >
          <header class="card-header">
            <p class="card-header-title has-background-primary has-text-white" v-if="!membershipRenewed && needsRenewal" >
              Renew Your Membership
            </p>
            <p class="card-header-title has-background-primary has-text-white" v-if="membershipRenewed" >
              Thank You, your membership has been renewed!
            </p>
          </header>
          <form>
            <div v-for="(membership, index) in membershipPlans" :key="membership.arm_subscription_plan_id">
              <div class="card-content" v-if="user.age >= 60 && membership.is_over_60plan && currentMembership.arm_current_plan_detail.arm_subscription_plan_id !== membership.arm_subscription_plan_id" >
                <div class="content has-text-left"  >
                    <label class="box" :for="'membership-'+index">                  
                      <input :id="'membership-'+index" name="club-membership" type="radio" :value="membership.arm_subscription_plan_id" @click="setPlan(index,membership.arm_subscription_plan_description, membership.arm_subscription_plan_id, membership.arm_subscription_plan_amount, membership.arm_subscription_plan_type)" ><h3 class="is-size-6 m-0">{{membership.arm_subscription_plan_name}}</h3>
                      {{membership.arm_subscription_plan_description}} - &pound;{{membership.arm_subscription_plan_amount}} per year
                    </label>
                </div>
              </div>
              <div class="card-content" v-else-if="user.age < 60 && !membership.is_over_60plan && currentMembership.arm_current_plan_detail.arm_subscription_plan_id !== membership.arm_subscription_plan_id" >
                <div class="content has-text-left"  >
                    <label class="box" :for="'membership-'+index">                  
                      <input :id="'membership-'+index" name="club-membership" type="radio" :value="membership.arm_subscription_plan_id" @click="setPlan(index,membership.arm_subscription_plan_description, membership.arm_subscription_plan_id, membership.arm_subscription_plan_amount, membership.arm_subscription_plan_type)" ><h3 class="is-size-6 m-0">{{membership.arm_subscription_plan_name}}</h3>
                      {{membership.arm_subscription_plan_description}} - &pound;{{membership.arm_subscription_plan_amount}} per year
                    </label>
                </div>
              </div>
            </div>
            <div class="px-5 pb-4" v-if="!membershipRenewed && needsRenewal" >
              <p v-if="stripeErr" class="has-text-danger" >{{stripeErr}}</p>
              <!--<div id="paypal-button-container" class="m-5" ></div>-->
              <!-- stripe-checkout :lineItems="[{price:amount,quantity:1}]"  -->
              <!--<stripe-checkout 
              :pk="stripe.pKey" 
              :clientReferenceId="item_name"
              :sessionId="user_id" 
              
              mode="payment" 
              :customerEmail="user.email"
              :items="[{plan: item_name, quantity:1}]" >
              <button @click="renewMembership">Renew Membership</button>
              </stripe-checkout> -->
              <!--<stripe-elements
              ref="elementsRef"
              :pk="stripe.pKey"
              :amount="amount"
              @token="tokenCreated"
              @loading="loading = $event"
            >
            </stripe-elements>
            <stripe-checkout
              ref="checkoutRef"
              mode="payment"
              :pk="publishableKey"
              :line-items="lineItems"
              :success-url="successURL"
              :cancel-url="cancelURL"
              @loading="v => loading = v"
            />
            <club-stripe />
            {{stripePaymentElement}}-->
            <form id="paymentForm" >
              <div id="paymentElement" >
                
              </div>
              <button class="button is-fullwidth is-primary" @click="goToMembersAreaOnwebsite">Renew My Membership!</button>
            </form>
            </div>
            
          </form>
          
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import store from '@/store';
  import config from '@/config';
  import axios from 'axios';
  import he from 'he';
  ///import ClubStripe from './ClubStripe.vue';
  ///import { loadScript } from "@paypal/paypal-js";
  ///import { StripeCheckout } from '@vue-stripe/vue-stripe';
  ///import {loadStripe} from '@stripe/stripe-js';

  export default {
    props: {
      userId: Number
    },
    emits: ["back"],
    components: {
        ///ClubStripe      
    },
    store: store,
    data() {
      return {
        base_url: '',
        user_id: 0,
        user: {},
        fn: '',
        ln: '',        
        em: '',
        pw: '',
        pwConfirm: '',
        dob: '',
        user_original_details:{},
        errors: [],
        tryLoginAgain: false,
        detailsUpdated: false,
        membershipUpdated: false,
        membershipRenewed: false,
        membershipPlans: [],
        currentMembership: {},
        selectedPlanId: 0,
        setUpId: config.setup_id,
        setUpAction: config.setup_action,
        paypalBtn: '',        
        subscription_type: config.default_subscription_type,
        item_name: '',
        item_number: 0,
        amount: 0,
        
        
        stripe: {},
        stripeElements: {},
        stripePaymentElement: {},
        stripeErr: '',
        token: null,
        charge: null,
        loading: false,
        entryId: 0,
        paypal: {
          
          business: '',
          notify_url: '',
          cancel_return: '',
          return: '',
          cmd: '_xclick',         
                   
          rm: 0,
          lc: '',
          no_shipping: 0,
          currency_code: '',
          page_style: '',
          charset: '',

          /// User Set data
          custom: '',
          on0: '',
          os0: '',
          
        }, 
        paypalErr: '',
        ipn: {
          payerID: '',
          orderID: '',
          facilitatorAccessToken: ''
        }
        
      }
    },
    mounted: function() {
      console.log('membership form view', this, store.state.user);
      //console.log('$refs', process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY , this.picOptions);
      
    },
    async created() {

      this.base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
      this.getAvailableMemberships();
      this.loadMember();
      

    },
    computed: {
      form_random_key(){
        return this.setUpId + '_' + this.randomCode();
      },
      form_random_keyCheck(){
        return this.captchaCode();
      },
      needsRenewal(){
        
        /*
        let filteredPlans = this.membershipPlans.filter((el) => {
          console.log('el.is_over_60plan',this.user.age, el.is_over_60plan);
            if(this.user.age >= 60 && el.is_over_60plan) {
              return true;
            }
        });
        */
                
        
        let filteredPlans = this.membershipPlans.filter((el) => {
          ///console.log(el.arm_subscription_plan_id);
          
          if(this.user.age >= 60 && el.is_over_60plan && this.currentMembership.arm_current_plan_detail.arm_subscription_plan_id !== el.arm_subscription_plan_id) {
            return true;
          } else if (this.user.age < 60 && !el.is_over_60plan && this.currentMembership.arm_current_plan_detail.arm_subscription_plan_id !== el.arm_subscription_plan_id){
            return true;            
          } else if(this.currentMembership.expiry_date_formatted === '01/01/1970' ) {
            return true
          } else {
            return false;
          }

        });

        console.log('FILTERE MEMBERSHIPS', filteredPlans);

        let nR = (filteredPlans.length > 0);
        if(nR) {
          ///this.loadPayPalDefaults();
          ///this.loadStripeDefaults();
        }
        
        return nR;

      }
    },	
    methods: {
      submit () {
        // this will trigger the process
        this.$refs.elementRef.submit();
      },
      tokenCreated (token) {
        console.log(token);
        // handle the token
        // send it to your server
        this.token = token;
        // for additional charge objects go to https://stripe.com/docs/api/charges/object
        this.charge = {
          source: token.card,
          amount: this.amount,
          description: this.description
        }
        this.sendTokenToServer(this.charge);
      },
      sendTokenToServer(charge){
        // Send to server
        console.log('charge', charge);
      },

      captchaCode(length = 8) {
          const possible_letters = '23456789bcdfghjkmnpqrstvwxyz';
          /*const random_dots = 0;
          const random_lines = 20;*/
          let code = '';
          for (let i = 0; i < length; i++) {
              let rand = Math.round(Math.random(0, possible_letters.length - 1) * 10);
              code += possible_letters[rand];
          }
          return code;
      },
      randomCode: function(length = 10) {
          const charLength = Math.round(length * 0.8);
          const numLength = Math.round(length * 0.2);
          const keywords = [
              {
                "count": charLength,
                "char": "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
              },
              {
                "count": numLength,
                "char": "0123456789"
              }
            ];
          
          const temp_array = keywords.map(function(char_set) {
              let n_array = [];
              for (let i = 0; i < char_set['count']; i++) {
                n_array.push(char_set['char'][Math.round(Math.random(0, char_set['char'].length - 1) * 10)]);
              }
              return n_array.join('');
          }); 
          return temp_array.join('');
      },      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      loadMember(){
        
        this.user_original_details = this.user = store.state.user;
        this.user_id = this.userId;
        this.memberNumber = this.user_id;
        //this.fn = this.user?.name.split(':')[0];
        //this.ln = this.user?.name.split(':')[1];
        this.fn = this.user?.first_name;
        this.ln = this.user?.last_name;
        this.dob = this.user?.dob;
        this.em = this.user?.email;
        this.pw = this.user?.pw;
        this.currentMembership = this.user?.current_plan;
        console.log('this.user..n', this.user);
      },
      loadPayPalDefaults(){
        this.paypal = {...this.paypal, ...config.paypal};
        
        this.paypal.notify_url = this.base_url + '/' + config.paypal.notify_url;
        this.paypal.cancel_return = this.base_url + '/' + config.paypal.cancel_return;
        this.paypal.return = this.base_url + '/' + config.paypal.return;
        this.paypal.business = (config.env === 'sb') ? config.paypal.sb_business : config.paypal.lv_business;

        this.paypal.custom = `-1|${this.em}|one_time`;
        this.paypal.on0 = 'user_email';
        this.paypal.os0 = this.em;

        //const creds = (config.env === 'sb') ? config.paypal.credentials.sandbox : config.paypal.credentials.production;
        //const debug = (config.env === 'sb');
        //var self = this;
                
          
      },
      /*async loadStripeDefaults() {

        ///var self = this;

        this.stripe = await loadStripe(this.picOptions.pk);/*.then((response) => {
          console.log('stripe load response',response);
        });*--/
        window.stripe = this.stripe;
        console.log('this.stripe', this.stripe);
        //let sp = {...this.stripe, ...config.stripe};
        ///this.stripeConfig = (config.env === 'sb') ? sp.sb_credentials : sp.lv_credentials;
        ///const id = 'paymentElement';

        
        var elements = this.stripe.elements({
          ///clientSecret: this.picOptions.sk
        });

        
        
        let options = {
          'business': {
            'name' : 'Portsmouth Irish Society'
          }
        };
        this.stripePaymentElement = elements.create('payment',options);
        console.log(this.stripePaymentElement);
        this.stripePaymentElement.mount('#paymentElement');

        this.stripe.confirmSetup({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: 'https://127.0.0.1:8080',
          },
        }).then(function(result) {
          if (result.error)
          // Inform the customer that there was an error.
          console.log('error', result);
        });
        

      },*/
      getAvailableMemberships() {
        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          const withCredentials = {
            'withCredentials': true
          };
          const data = {'arm_setup_id' : config.setup_id};
          
          const url = this.base_url + '/' + config.rest_endpoint + '/' + config.endpoints.memberships + '/' + config.setup_id;
          axios.get(url,
            data,
            {
              headers: headers
            },
            withCredentials,
          ).then((response) => {

              console.log('response', response, response.headers);

              if(response.data.error) {
                this.errors.push(response.data.error);
              }
              
              if(response.data.error === undefined){                
                console.log('calling membership data', response.data.data.available_plans);
                /*
                sessionStorage.setItem(
                    "user_data_", ///+response.data.data.user_id
                    JSON.stringify(response.data.data)
                );
                this.$emit('logIn', response.data.data);
                */
               this.membershipPlans = response.data.data.available_plans;
               
                
              }
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }
      },
      setPlan(index, name, id, amount, subscription_type){
        this.item_name = name.toString();
        this.item_number = id.toString();
        this.amount = amount.toString();
        this.subscription_type = subscription_type;
        this.selectedPlanId = index;
        this.stripeErr = '';
        console.log(this.stripe);
      },
      updateMember(e){
        e.preventDefault()
        console.log('called updateMember', this);
        
        if(this.pw !== this.pwConfirm && this.pwConfirm.length > 0) {
          alert('passwords must match');
        }

        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          const withCredentials = {
            'withCredentials': true
          };
          const data = {
            user_firstname: this.fn,
            user_lastname: this.ln,
            user_email: this.em, 
            user_password: this.pw,
            user_password_confirm: this.pwConfirm,
            user_dob: this.dob,
            user_original_details: this.user_original_details
          };
          
          const url = this.base_url + '/' + config.rest_endpoint + '/' + config.endpoints.update_member;
          axios.post(url,
            data,
            {
              headers: headers
            },
            withCredentials
          ).then((response) => {

              console.log('response', response, response.headers);

              if(response.data.error) {
                this.errors.push(response.data.error);
                this.tryLoginAgain = true;
              }
              
              if(response.data.error === undefined){                
                
                console.log('calling emmit');
                
                sessionStorage.setItem(
                    "user_data_", ///+response.data.data.user_id
                    JSON.stringify(response.data.data)
                );
                
                this.membershipUpdated = true;
                
              }
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }

      },
      goToMembersAreaOnwebsite(e){
        
        e.preventDefault();
        if(this.item_number <= 0) {
          alert('Please select a valid membership');
          return false;
        }
        
        let url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
        let encLogin = {user_email: this.user.email.trim(), user_pass: this.user.pw.trim(), selected_membership_id: this.item_number};
        encLogin = JSON.stringify(encLogin);
        encLogin = btoa(encLogin);
        url += '/member-renew/'+encLogin;
        window.location = url;

      },
      gotoPay(e){
        e.preventDefault();
        let self = this;
        console.log('_)____CLICKED :_)', this, self);
        //// Send Data to enter Form Data

        const formData = new FormData();

        formData.append('action', 'arm_membership_setup_form_ajax_action');
        formData.append('form_random_key', this.form_random_key);
        formData.append('setup_id', this.setUpId);
        formData.append('setup_action', this.setUpAction);
        formData.append('arm_user_old_plan', this.user.last_plan);
        formData.append('arm_is_user_logged_in_flag', '1');
        formData.append('arm_front_plan_skin_type', config.skin);
        formData.append('subscription_plan', this.paypal.item_number);
        formData.append('arm_plan_type', this.subscription_type);
        formData.append('arm_front_gateway_skin_type', 'radio');
        formData.append('receiver_email', this.paypal.business);
        formData.append('arm_selected_payment_cycle' , 0);
        formData.append('payment_gateway', 'stripe');
        formData.append('arm_payment_mode[stripe]', 'both');
        formData.append('arm_payment_mode[bank_transfer]', 'manual_subscription');
        formData.append('bank_transfer[transaction_id]', '');
        formData.append('bank_transfer[bank_name]','');
        formData.append('bank_transfer[account_name]','');
        formData.append('bank_transfer[additional_info]','');
        formData.append('arm_selected_payment_mode', config.arm_selected_payment_mode);
        formData.append('arm_total_payable_amount', this.paypal.amount);
        formData.append('arm_zero_amount_discount', '0.00');
        formData.append('user_login', this.em);
        formData.append('user_id', this.user_id)
        formData.append('user_password', this.pw);
        formData.append([this.form_random_keyCheck], '');

        console.table(formData);

        const headers = {
            'Content-Type': 'application/json',
            ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
            'Authorization': 'picsitekey:'+config.site_key,
            'Accept' : 'multipart/form-data'
        };
        const withCredentials = {
          'withCredentials': true
        };
        
        const data = formData;
        const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
        ///const url = base_url + '/wp-admin/admin-ajax.php';
        ///const url = base_url + '/wp-admin/admin-ajax.php';
        const url = base_url + '/' + config.rest_endpoint + '/' + config.endpoints.create_entry;
        axios.post(url,
          data,
          {
            headers: headers
          },
          withCredentials
        ).then((response) => {

            console.log('response create entry', response, response.headers);
            /*
            if(response.data.error) {
              this.errors.push(response.data.error);
            }
            
            if(response.data.error === undefined){                
              console.log('calling form send data', response);
              /---*
              sessionStorage.setItem(
                  "form_data_", ///+response.data.data.user_id
                  JSON.stringify(response)
              );
              this.$emit('logIn', response.data.data);
                                      
            }*/
            console.log('response.data.data)', response.data.data);
            self.entryId = response.data.data.entry_id;
            
            console.log(self);
            
            
        })
        .catch((e) => {
            ///dispatch({type: ERROR_FINDING_USER})
            self.errors.push(e);
            ///this.state.user({data: this.errors});
            
        });

      },
      requestStripePayment(){
        this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.stripePaymentElement,
          billing_details: {
            name: 'Joe Blogs',
          },
        })
        .then(function(result) {
          // Handle result.error or result.paymentMethod
          console.log('result is', result);
        });
      },
      renewMembership(){

        console.log('called renewMembership', this);

        try {
          
          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          const withCredentials = {
            'withCredentials': true
          };
          
          /** REF
          payer_email: sb-rdly61018141@personal.example.com
          payer_id: GMJTW6Y3G7R5E
          payer_status: VERIFIED
          first_name: John
          last_name: Doe
          txn_id: 6G493881HA9899613
          mc_currency: GBP
          mc_fee: 0.88
          mc_gross: 20.00
          protection_eligibility: ELIGIBLE
          payment_fee: 0.88
          payment_gross: 20.00
          payment_status: Completed
          payment_type: instant
          handling_amount: 0.00
          shipping: 0.00
          item_name: Club Membership 2022-2023
          item_number: 1
          quantity: 1
          txn_type: web_accept
          option_name1: user_email
          option_selection1: andileedavis@gmail.com
          payment_date: 2021-11-02T17:15:19Z
          business: sb-amycr1023675@business.example.com
          receiver_id: 3R4PYBVND6L8L
          notify_version: UNVERSIONED
          custom: 4|andileedavis@gmail.com|one_time
          verify_sign: An5idV7hrMq-VYPAOaZ.dkiiYqsTAqGc6XAqJS.yY-v27cHQxoS.nc52
          */

          let d = new Date();          
          let payment_date = d.toISOString();

          const data = {
            payer_email: this.em,
            payer_id: this.ipn.payerID,
            subscr_id: this.paypal.item_number.toString(),
            payer_status: 'VERIFIED',
            txn_id: this.ipn.orderID,
            mc_currency: this.paypal.currency_code,
            mc_fee: '0.00',
            mc_gross: this.paypal.amount,
            payment_status: 'Completed',
            payment_type: 'instant',
            handling_amount: '0.00',
            shipping: '0.00',
            item_name: this.paypal.item_name.toString(),
            item_number: this.paypal.item_number.toString(),
            quantity: '1',
            txn_type: 'web_accept',
            option_name1: 'user_email',
            option_selection1: this.em,
            payment_date: payment_date,
            business: this.paypal.business,
            receiver_email: this.paypal.business,
            arm_selected_payment_cycle: 0,
            ///receiver_id: 3R4PYBVND6L8L,
            notify_version: 'UNVERSIONED',
            custom: `${this.paypal.entryId}|${this.em}|one_time`,
            verify_sign: this.ipn.facilitatorAccessToken,
            'arm-listener': 'arm_paypal_notify',
            user_login: this.em,
            user_password: this.pw            
          };
          
          const url = this.base_url + '/' + config.rest_endpoint + '/' + config.endpoints.renew_membership;

          //const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
          //const url = base_url + '/wp-admin/admin-ajax.php';
          axios.post(url,
            data,
            {
              headers: headers
            },
            withCredentials
          ).then((response) => {

              console.log('Order response', response, response.headers);
              /*
              if(response.data.error) {
                this.errors.push(response.data.error);
              }
              
              if(response.data.error === undefined){                
                console.log('calling form send data', response);
                /---*
                sessionStorage.setItem(
                    "form_data_", ///+response.data.data.user_id
                    JSON.stringify(response)
                );
                this.$emit('logIn', response.data.data);
                                        
              }*/
              this.membershipRenewed = true;
              this.needsRenewal = false;
              this.currentMembership.arm_current_plan_detail.arm_subscription_plan_id = this.membershipPlans[this.selectedPlanId].arm_subscription_plan_id;
              this.currentMembership.arm_current_plan_detail.arm_subscription_plan_name = this.membershipPlans[this.selectedPlanId].arm_subscription_plan_name;
              this.currentMembership.arm_current_plan_detail.arm_subscription_plan_type = this.membershipPlans[this.selectedPlanId].arm_subscription_plan_type;
              
              let sd = new Date().toLocaleDateString({year: 'numeric', month: '2-digit', day: '2-digit'});
              let ed = new Date(this.membershipPlans[this.selectedPlanId].arm_subscription_plan_options.expiry_date).toLocaleDateString({year: 'numeric', month: '2-digit', day: '2-digit'}); 
              
              this.currentMembership.start_date_formatted = sd;
              this.currentMembership.expiry_date_formatted = ed;
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              self.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });          
          
        } catch (e) {
          this.errors.push(e)
        }

      },
      back(){
        this.$emit('back');
      }
    },

    beforeUnmount: function() {
      
    }    
  }
</script>
<style scoped>
.card {
  max-width: none;
}
@media screen and (mx-width: 768px) {
  .columns {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>