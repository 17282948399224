<template>
  
  <header class="heading">
    Log In
  </header>
  <div class="card" ><!-- v-if="!isStream" -->
    <div v-if="loading" class="card-content">
      <div class="card-content button is-loading" ></div>
    </div>  
    <div v-else class="card-content">       
      <div  class="content has-text-left">
        <form :class="getError() ? 'has-text-danger' : ''">
          <span v-if="tryLoginAgain" class="has-text-danger is-size-7" >{{getError()}}</span>
          <p><label for="email" >Email or Membership Number: </label><input id="email" class="input" type="text" placeholder="Email, Username or Card Number" v-model="em" ></p>
          <p><label for="password" >Password: </label><input id="password" class="input" type="password" placeholder="********" v-model="pw" ></p>
          <p class="mb-7"><button class="button is-success is-fullwidth" @click="tryLogin" >Log In</button></p>
          <p class="is-flex is-justify-content-space-around is-align-content-center" >
            <a class="is-warning" @click="forgotPassword" >Forgot Password</a> 
            <a class="is-warning" :href="registerUrl" target="_blank" >Register</a>
          </p>            
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import store from '@/store';
  import config from '@/config';
  import axios from 'axios';
  import he from 'he'; 
  
  export default {
    props: {
      
    },
    emits: ["logIn", "forgotPassword", "register"],
    components: {
      
    },
    store: store,
    data() {
      return {
        em: '',
        pw: '',
        errors: [],
        registerUrl: '',
        tryLoginAgain: false,
        loading: false
      }
    },
    mounted: function() {
      const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
      this.registerUrl = base_url + '/register/';
    },
    async created() {
      
    },
    computed: {
      
    },	
    methods: {
      
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      tryLogin(e){
        e.preventDefault()
        console.log('called tryLogin', this, this.em, this.pw);
        this.loading = true;

        try {

          const headers = {
              'Content-Type': 'application/json',
              ///'Content-Type' : 'multipart/form-data; charset=UTF-8',
              'Authorization': 'picsitekey:'+config.site_key,
              'Accept' : 'multipart/form-data'
          };
          const withCredentials = {
            'withCredentials': true
          };
          const data = {user_login: this.em, user_password: this.pw};
          const base_url = (config.env === 'sb') ? config.sb_site_url : config.lv_site_url;
          const url = base_url + '/' + config.rest_endpoint + '/' + config.endpoints.login;
          axios.post(url,
            data,
            {
              headers: headers
            },
            withCredentials,
          ).then((response) => {

              console.log('response', response, response.headers);

              if(response.data.error) {
                this.errors.push(response.data.error, response.data.data);
                this.tryLoginAgain = true;
              }
              
              if(response.data.error === undefined){                
                console.log('calling emmit');
                
                let user_data = {...response.data.data, ...{em: this.em, pw: this.pw}};
                let user_data_str = JSON.stringify(user_data);

                sessionStorage.setItem(
                    "user_data_", ///+response.data.data.user_id
                    user_data_str
                );
                this.$emit('logIn', user_data);                      
                
              }

              this.loading = false;
              
          })
          .catch((e) => {
              ///dispatch({type: ERROR_FINDING_USER})
              this.errors.push(e);
              ///this.state.user({data: this.errors});
              
          });
          
        } catch (e) {
          this.errors.push(e)
        }

      },
      forgotPassword(){
        this.$emit('forgotPassword');
      },
      register(){
        this.$emit('register');
      },
      getError(){
        if(this.errors.length >= 1) {
          //let errorMessage = [...Object.values(this.errors[1])];
          //return `${this.errors[0]} : ${errorMessage}`;
          return 'There was an error logging in, the credentials entered were incorrect';
        }
      }
    },

    beforeUnmount: function() {
      
    }    
  }
</script>