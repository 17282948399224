<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand mr-2">
    <!--<a class="navbar-item" href="https://bulma.io">
      <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28">
    </a>-->
    <img :class="((pageId <= 0) ? 'is-hidden-touch' : '')" alt="Ireland Flag" src="assets/logo-60.png" width="60" >
    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="openMenu" >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="appMainMenu" class="navbar-menu">
    <div class="navbar-start">
      
      <a class="navbar-item" @click="getPage(0)">
        Home
      </a>      

      <a class="navbar-item" @click="getPage(121)" >
        What's On
      </a>

      <a class="navbar-item" @click="getMembersPage(790)" >
        Constitution
      </a>

      <a class="navbar-item" @click="getStaticPage('Offers')" >
        Membership Special Offers
      </a>

      <a class="navbar-item" @click="getStaticPage('ThingsToDo')" >
        Days Out &amp; Things to do!
      </a>

      <a class="navbar-item" @click="getStaticPage('RTENews')" >
        News from RTÉ
      </a>

      <!--<a class="navbar-item">
        Games
      </a>-->

      <!--<div class="navbar-item has-dropdown is-hoverable is-hidden-touch">
        <a class="navbar-link">
          More
        </a>

        <div class="navbar-dropdown">
          
          <a class="navbar-item" @click="getPage()" >
            Help
          </a>
          <a class="navbar-item">
            Bookings
          </a>
          <a class="navbar-item">
            Jobs
          </a>
          <a class="navbar-item">
            Contact
          </a>
          <hr class="navbar-divider">
          <a class="navbar-item">
            Report an issue
          </a>
        </div>
      </div>-->

      <!--<div class="navbar-item is-hidden-desktop" >
        <a class="navbar-item">
          About
        </a>
        <a class="navbar-item">
          Bookings
        </a>
        <a class="navbar-item">
          Jobs
        </a>
        <a class="navbar-item">
          Contact
        </a>
        <hr class="navbar-divider">
        <a class="navbar-item">
          Report an issue
        </a>
      </div>-->
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons is-centered">
          <a class="button is-danger" @click="logOut">
            <strong>Log Out</strong>
          </a>
          <a class="button is-light" @click="getForm('membership')" >
            My Membership
          </a>
        </div>
        
      </div>
      
    </div>
  </div>
</nav>
</template>
<script>
  import store from '@/store';
  import he from 'he'; 
  
  export default {
    props: {
      
    },
    emits: ["getStaticPage", "getPage", "getMembersPage", "getForm", "logOut"],
    components: {
      
    },
    store: store,
    data() {
      return {
        pageId: 0,
        target: 'appMainMenu',
        errors: []
      }
    },
    mounted: function() {
    },
    async created() {
      
    },
    computed: {
      
    },	
    methods: {

      openMenu(){
        // Get the target from the "data-target" attribute
        ///this.target = e.target.dataset.target;
        this.toggleMenu();
                
      },
      toggleMenu(){
        const $target = document.getElementById(this.target);
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        ///target.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      },
      closeMenu(){
        const $target = document.getElementById(this.target);
        $target.classList.remove('is-active');
      },   
      encode (value) {
        if(value) {
          this.name = he.encode(value)
        }
      },
      decode (value) {
        if(value) {
          return he.decode(value)
        }
      },
      toggleDebug: function(){
        this.debug=!this.debug;
        document.body.classList.toggle('debug');
      },
      getPage(pageId){
        this.staticPageName = '';
        this.pageId = pageId;
        console.log('this.target', this.target);
        if(this.target !== '') {
          this.toggleMenu();
        }        
        this.$emit('getPage', pageId);
      },
      getMembersPage(pageId){
        this.staticPageName = '';
        this.pageId = pageId;
        console.log('this.target', this.target);
        if(this.target !== '') {
          this.toggleMenu();
        }        
        this.$emit('getMembersPage', pageId);
      },
      getStaticPage(staticPageName){
        this.pageId = 0;
        this.staticPageName = staticPageName;
        console.log('getStaticPage.target', this.target, this.staticPageName);
        if(this.target !== '') {
          this.toggleMenu();
        }     
        this.$emit('getStaticPage', staticPageName);
      },
      getForm(form){
        this.closeMenu();
        this.$emit('getForm', form);
      },
      logOut(){
        this.$emit('logOut');
      }
    },

    beforeUnmount: function() {
      
    }    
  }
</script>